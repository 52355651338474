// route component (called from App.js)
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { useAuthState } from '../Contexts/MainContext'

const AppRoutes = ({ component: Component, path, exact = false, isPrivate, ...rest }) => {
	const authStatus = useAuthState()

	return (
		<Route
			exact={exact}
			path={path}
			render={(props) =>
				isPrivate && !Boolean(authStatus.token) ? (
					<Redirect to={{ pathname: '/login' }} />
				) : (
					<Component {...props} />
				)
			}
			{...rest}
		/>
	)
}

export default AppRoutes
