let user = localStorage.getItem('user')
	? JSON.parse(localStorage.getItem('user'))
	: ''
let token = localStorage.getItem('token')
	? JSON.parse(localStorage.getItem('token'))
  : ''
let seasons = localStorage.getItem('seasons')
	? JSON.parse(localStorage.getItem('seasons'))
	: ''

export const initialState = {
	user: '' || user,
	token: '' || token,
	seasons: '' || seasons,
	loading: false,
	errorMessage: null,
}

export const AuthReducer = (initialState, action) => {
	switch (action.type) {
		// all-purpose
		case 'REQUEST':
			return {
				...initialState,
				loading: true,
			}
		case 'SUCCESS':
			return {
				...initialState,
				loading: false,
				errorMessage: null
			}
		case 'ERROR':
			let message = 'Unknown error'
			if (action.error) {
				message = action.error
			} else if (action.error.message) {
				message = action.error.message
			}
			if (process.env.NODE_ENV === 'development') {
				console.log(message)
			}
			return {
				...initialState,
				loading: false,
				errorMessage: message,
			}

		// runway show seasons
		case 'SEASONS_SUCCESS':
			return {
				...initialState,
				seasons: action.payload,
				loading: false,
				errorMessage: null
			}

		// login
		case 'LOGIN_SUCCESS':
			return {
				...initialState,
				token: action.payload.token,
				loading: false,
				errorMessage: null
			}

		// get user
		case 'GET_USER_SUCCESS':
			return {
				...initialState,
				user: action.payload,
				loading: false,
				errorMessage: null
			}

		case 'PUT_SUCCESS':
			return {
				...initialState,
				user: action.payload,
				loading: false,
				errorMessage: null
			}

		// logout
		case 'LOGOUT':
			return {
				...initialState,
				loading: false,
				token: '',
				user: '',
				seasons: '',
				errorMessage: null
			}

		default:
			throw new Error(`Unhandled action type: ${action.type}`)
	}
}
