import ChangePassword from '../Pages/Account/Password/ChangePassword'
import LostPassword from '../Pages/Account/Password/LostPassword'
import Login from '../Pages/Account/Login'
import Profile from '../Pages/Account/Profile'
import Registration from '../Pages/Account/Registration'
import Validation from '../Pages/Account/Validation'

import Home from '../Pages/Content/Home'

import RunwayShows from '../Pages/Content/RunwayShows/'
import TrendBookFront from '../Pages/Content/TrendBooks/Front'
import TrendBookList from '../Pages/Content/TrendBooks/List'
import TrendBookViewer from '../Pages/Content/TrendBooks/Viewer'
import TrendReportList from '../Pages/Content/TrendReports/List'
import TrendReportArticle from '../Pages/Content/TrendReports/Article'

import About from '../Pages/Aside/About'
import Agents from '../Pages/Aside/Agents'
import Privacy from '../Pages/Aside/Privacy'
import Disclaimer from '../Pages/Aside/Disclaimer'
import Help from '../Pages/Aside/Help'
import Terms from '../Pages/Aside/Terms'

import NotFound from '../Pages/NotFound'

const routes = [
	{
		path: '/login',
		component: Login,
		isPrivate: false,
	},
	{
		path: '/lost-password',
		component: LostPassword,
		isPrivate: false,
	},
	{
		path: '/change-password',
		component: ChangePassword,
		isPrivate: false,
	},
	{
		path: '/registration',
		component: Registration,
		isPrivate: false,
	},
	{
		path: '/validation',
		component: Validation,
		isPrivate: false,
	},
	{
		path: '/profile',
		component: Profile,
		isPrivate: true,
	},
	{
		path: '/',
		component: Home,
		isPrivate: true,
		exact: true
	},
	{
		path: '/trend-books/:id',
		component: TrendBookList,
		isPrivate: true
	},
	{
		path: '/trend-book/:id',
		component: TrendBookFront,
		isPrivate: true,
		exact: true
	},
	{
		path: '/trend-book/:id/viewer',
		component: TrendBookViewer,
		isPrivate: true,
		exact: true
	},
	{
		path: '/runway-shows/:seasonID/:categoryID?/:sectionID?',
		component: RunwayShows,
		isPrivate: true
	},
	{
		path: '/trend-reports/:id',
		component: TrendReportList,
		isPrivate: true
	},
	{
		path: '/trend-report/:id',
		component: TrendReportArticle,
		isPrivate: true,
		exact: true
	},
	{
		path: '/about',
		component: About,
		isPrivate: true
	},
	{
		path: '/agents',
		component: Agents,
		isPrivate: true
	},
	{
		path: '/disclaimer',
		component: Disclaimer,
		isPrivate: true
	},
	{
		path: '/help',
		component: Help,
		isPrivate: true
	},
	{
		path: '/privacy',
		component: Privacy,
		isPrivate: true
	},
	{
		path: '/terms',
		component: Terms,
		isPrivate: true
	},
	{
		path: "*",
		component: NotFound,
		isPrivate: false,
	}
]

export default routes
