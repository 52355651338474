import fr from './fr.json'
import en from './en.json'

export const dictionaryList = { en, fr }

export const languageOptions = {
  en: {
    "shortName": "EN",
    "fullName": "English"
  },
  fr: {
    "shortName": "FR",
    "fullName": "Français"
  }
}
