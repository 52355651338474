import React, { useContext, useState } from 'react'

import { LanguageContext } from '../../../../../Contexts/Language'

function lightOrDark(color) {

	// https://codepen.io/andreaswik/pen/YjJqpK

	let r, g, b, hsp
	
	if (color) {
		if (color.match(/^rgb/)) {
			color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)
			r = color[1]
			g = color[2]
			b = color[3]
		} 
		else {
			color = +("0x" + color.slice(1).replace( 
				(color.length < 5) && /./g, '$&$&'
			))
			r = color >> 16
			g = (color >> 8) & 255
			b = color & 255
		}
		hsp = Math.sqrt(
			0.299 * (r * r) +
			0.587 * (g * g) +
			0.114 * (b * b)
		)
		if (hsp>127.5) {
			return 'light'
		} 
		else {
			return 'dark'
		}
	}
}

function ColorItem({ colorData }) {

	const { dictionary } = useContext(LanguageContext)

  const [isExpanded, setIsExpanded] = useState(false)

  function toggleExpand() {
    setIsExpanded(isExpanded => !isExpanded)
	}

	let backgroundStyle = null,
			colorItemClass = "pdf-viewer--downloads--color-item"

	if (colorData.thumbnail) {
		backgroundStyle = {
			backgroundImage: `url(${colorData.thumbnail})`
		}
	} else if (colorData.hex) {
		backgroundStyle = {
			backgroundColor: `${colorData.hex}`
		}
	}

	if (lightOrDark(colorData.hex) === 'dark') {
		colorItemClass += ' dark'
	}
	else {
		colorItemClass += ' light'
	}

  return (
		<div
			className={colorItemClass}
		>
			{
				isExpanded ? (
					<div className="pdf-viewer--downloads--color-item--content pdf-viewer--downloads--color-item--content-more" style={backgroundStyle}>
						<button
							type="button"
							className="btn-unstyled pdf-viewer--downloads--color-item--btn less-btn pdf-viewer--downloads--color-item--btn-less"
							onClick={toggleExpand}
							aria-label={dictionary.trendBooks.downloads.more}
						></button>
						<table className="pdf-viewer--downloads--color-item--table">
							<tbody>
								{
									colorData.hex ? (
										<tr>
											<td><span className="pdf-viewer--downloads--color-item--th">Hex</span>{ colorData.hex }</td>
											<td>
												<button
													type="button"
													className="btn-unstyled pdf-viewer--downloads--color-item--copy-btn"
													onClick={() => {navigator.clipboard.writeText(colorData.hex)}}
												>
													{dictionary.buttons.copy}
												</button>
											</td>
										</tr>
									) : null
								}
								{
									colorData.pantone_1 ? (
										<tr>
											<td><span className="pdf-viewer--downloads--color-item--th">Pantone</span>{ colorData.pantone_1 }</td>
											<td>
												<button
													type="button"
													className="btn-unstyled pdf-viewer--downloads--color-item--copy-btn"
													onClick={() => {navigator.clipboard.writeText(colorData.pantone_1)}}
												>
													{dictionary.buttons.copy}
												</button>
											</td>
										</tr>
									) : null
								}
								{
									colorData.pantone_2 ? (
										<tr>
											<td><span className="pdf-viewer--downloads--color-item--th">Pantone</span>{ colorData.pantone_2 }</td>
											<td>
												<button
													type="button"
													className="btn-unstyled pdf-viewer--downloads--color-item--copy-btn"
													onClick={() => {navigator.clipboard.writeText(colorData.pantone_2)}}
												>
													{dictionary.buttons.copy}
												</button>
											</td>
										</tr>
									) : null
								}
								{
									colorData.rvb ? (
										<tr>
											<td><span className="pdf-viewer--downloads--color-item--th">RVB</span>{ colorData.rvb }</td>
											<td>
												<button
													type="button"
													className="btn-unstyled pdf-viewer--downloads--color-item--copy-btn"
													onClick={() => {navigator.clipboard.writeText(colorData.rvb)}}
												>
													{dictionary.buttons.copy}
												</button>
											</td>
										</tr>
									) : null
								}
								{
									colorData.coloro ? (
										<tr>
											<td><span className="pdf-viewer--downloads--color-item--th">Coloro</span>{ colorData.coloro }</td>
											<td>
												<button
													type="button"
													className="btn-unstyled pdf-viewer--downloads--color-item--copy-btn"
													onClick={() => {navigator.clipboard.writeText(colorData.coloro)}}
												>
													{dictionary.buttons.copy}
												</button>
											</td>
										</tr>
									) : null
								}
							</tbody>
						</table>
					</div>
				) : (
					<div className="pdf-viewer--downloads--color-item--content pdf-viewer--downloads--color-item--content-less" style={backgroundStyle}>
						<button
							type="button"
							className="btn-unstyled pdf-viewer--downloads--color-item--btn more-btn pdf-viewer--downloads--color-item--btn-more"
							onClick={toggleExpand}
							aria-label={dictionary.trendBooks.downloads.less}
						></button>
						<div className="pdf-viewer--downloads--color-item--hex">
							{colorData.hex}
						</div>
						<div className="pdf-viewer--downloads--color-item--title">
							{colorData.title}
						</div>
					</div>
				)
			}
		</div>
  )
}

export default ColorItem
