// runway shows hub page
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'

import debounce from 'lodash.debounce'
import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom'

import CategoryNav from '../../../Components/CategoryNav'
import ExpiredCode from '../../../Components/ExpiredCode'
import Footer from '../../../Components/Footer'
import Header from '../../../Components/Header'
import Nav from '../../../Components/Nav'
import TrendReportGrid from '../../../Components/TrendReportGrid'

import useOnScreen from '../../../Utilities/useOnScreen'

import { LanguageContext } from '../../../Contexts/Language'
import { getTrendReportsByCategory, getTrendReportCategories, getTrendReportsHub, useAuthState, useAuthDispatch } from '../../../Contexts/MainContext'

function TrendReportList(props) {

  const { userLanguage, dictionary } = useContext(LanguageContext)
  
  const dispatch = useAuthDispatch()
  const { token } = useAuthState()

	const [displayHub, setDisplayHub] = useState(false)
	const [displayList, setDisplayList] = useState(false)

  const [categories, setCategories] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [nextPage, setNextPage] = useState()
  const [trendReportHub, setTrendReportHub] = useState([])
  const [trendReportList, setTrendReportList] = useState([])

  const pager = useRef()
  const isVisible = useOnScreen(pager)

  const items_per_page = 10

  const handleGetCategories = useCallback(async () => {
    try {
      let response = await getTrendReportCategories(dispatch, token, userLanguage)
      if (!response) return
      if (response.code === 'OK') {
        setCategories(response.data.rubrics)
      }
    } catch (error) {
      console.log(error)
    }
  }, [dispatch, token, userLanguage])

  const handleGetHub = useCallback(async () => {
    try {
      let response = await getTrendReportsHub(dispatch, token, userLanguage)
      if (!response) return
      if (response.code === 'OK') {
        setTrendReportHub(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }, [dispatch, token, userLanguage])

  const handleGetTrendReportList = useCallback(async (id, page) => {
    let payload = {
      "rubric": id,
      "item_per_page": items_per_page,
      "page": page,
    }
    try {
      setIsLoading(true)
      if (page === 0) {
        setTrendReportList([])
      }
      let response = await getTrendReportsByCategory(dispatch, token, userLanguage, payload)
      if (!response) return
      if (response.code === 'OK') {
        setIsLoading(false)
        if (response.data.tr_list.length) {
          const newList = Object.values(response.data.tr_list)
          setTrendReportList(trendReportList => trendReportList.concat(newList))
          setNextPage(page + 1)
          if (response.data.tr_list.length < items_per_page) {
            setHasMore(false)
          }
        } else {
          setHasMore(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }, [dispatch, token, userLanguage])

  const loadMore = () => {
    handleGetTrendReportList(props.match.params.id, nextPage)
  }

  const handleScroll = debounce(() => {
    if (isLoading || !hasMore || props.match.params.id === 'all') return
    if (isVisible) {
      loadMore()
    }
  }, 500)

  useEffect(() => {
    handleGetCategories()
  }, [handleGetCategories])

  useEffect(() => {
    setHasMore(true)
    setTrendReportList([])
    setTrendReportHub([])
    if (props.match.params.id === 'all') {
      handleGetHub()
    } else if (props.match.params.id !== null && props.match.params.id !== undefined) {
      handleGetTrendReportList(props.match.params.id, 0)
    }
  }, [userLanguage, props.match.params.id, handleGetHub, handleGetTrendReportList])

  useEffect(() => {
    if (trendReportHub.length) {
      setDisplayList(false)
      setDisplayHub(true)
    }
  }, [trendReportHub])

  useEffect(() => {
    if (trendReportList.length) {
      setDisplayHub(false)
      setDisplayList(true)
    }
  }, [trendReportList])

  return (
    <>
      <Helmet>
				<html lang={userLanguage} />
			</Helmet>
			<div className="page trend-reports">
				<ExpiredCode />
				<Nav {...props} />
				<div className="main-content" onScroll={handleScroll}>
					<Header title={ dictionary.nav.main.trendReports } text={ dictionary.trendReports.intro } />
          <div className="category-nav--wrapper">
            <CategoryNav>
              <NavLink to="/trend-reports/all" exact className="btn-unstyled nav-link">{ dictionary.trendReports.all }</NavLink>
              {
                categories.length > 0 ? (
                  categories.map(function(category) {
                    return (
                      <NavLink key={category.id} to={'/trend-reports/' + category.id} className="btn-unstyled nav-link">
                        { category.name }
                      </NavLink>
                    )
                  })
                ) : null
              }
            </CategoryNav>
          </div>
          {
            displayHub ? (
              <TrendReportGrid items={trendReportHub} useLarge={true} />
            ) : null
          }
          {
            displayList ? (
              <TrendReportGrid items={trendReportList} useLarge={true} />
            ) : null
          }
          <div ref={pager}></div>
					<Footer />
        </div>
      </div>
    </>
  )
}

export default TrendReportList
