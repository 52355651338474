import React, { useState, useEffect, useRef } from "react"

import classNames from 'classnames'

function LazyImage({ className, src, alt/*, lqip*/, aspectRatio }) {
  const [loaded, setLoaded] = useState(false)
  const imgRef = useRef()

  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setLoaded(true)
    }
  }, [])

  return (
    aspectRatio ? (
      <div className={classNames('lazy-image', className)}>
        <div style={{ paddingBottom: `${100 / aspectRatio}%` }} />
        {/* <img src={lqip} alt="" aria-hidden="true" /> */}
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" aria-hidden="true" alt={alt} />
        <img
          loading="lazy"
          src={src}
          alt={alt}
          ref={imgRef}
          onLoad={() => setLoaded(true)}
          className={classNames('lazy-image--source', loaded && 'lazy-image--loaded')}
        />
      </div>
    ) : (
      <div className={classNames('lazy-image', className)}>
        <img
          loading="lazy"
          src={src}
          alt={alt}
          ref={imgRef}
          onLoad={() => setLoaded(true)}
          className={classNames('lazy-image--source no-aspect-ratio', loaded && 'lazy-image--loaded')}
        />
      </div>
    )
  )
}
export default LazyImage
