import React, { useEffect, useRef, useState } from "react"

import classNames from 'classnames'

import useOutsideClick from '../../../../../Utilities/useOutsideClick'

const SelectTheme = ({ onChange, options, currentThemeID }) => {

  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState()

  const toggling = () => setIsOpen(!isOpen)

  const onOptionClicked = value => () => {
    setSelectedOption(value)
    setIsOpen(false)
    onChange(value.start)
  }

	const clickOutside = useRef()

	useOutsideClick(clickOutside, () => {
		setIsOpen(false)
  })

  useEffect(() => {
    setSelectedOption(options.find(x => x.id === currentThemeID))
  }, [options, currentThemeID])

  return (
    <div className="custom-select" ref={clickOutside}>
      <button type="button" className={classNames('btn-unstyled custom-select--trigger', {'active': isOpen})} onClick={toggling}>
        { selectedOption && selectedOption.title }
      </button>
      {isOpen && (
        <div className="custom-select--dropdown">
          <div className="custom-select--dropdown-list">
            {options.map((option, key) => (
              option.colors || option.harmonies || option.images || option.sketches ? (
                <button
                  key={key}
                  type="button"
                  className={classNames('btn-unstyled custom-select--dropdown--item', {'active': selectedOption === option})}
                  onClick={onOptionClicked(option)}
                >
                  {option.title}
                </button>
              ) : null
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default SelectTheme
