import React, { useContext } from 'react'

import { Link } from 'react-router-dom'

import LazyImage from './LazyImage'

import { LanguageContext } from '../Contexts/Language'

export default function TrendReportThumbnail(props) {

  const { dictionary, userLanguage } = useContext(LanguageContext)

  const data = props.item

  let formattedDate = data.published_at || data.date,
      dateConfig = null

  if (formattedDate) {
    formattedDate = new Date(formattedDate.replace(' ', 'T'))
    if (userLanguage === 'fr') {
      dateConfig = { year: 'numeric', month: 'long', day: 'numeric' }
    } else {
      dateConfig = { year: 'numeric', month: 'short', day: 'numeric' }
    }
    formattedDate = formattedDate.toLocaleDateString(userLanguage, dateConfig).replace(/\//g, '.')
  }

  if (props.large) {
    return (
      <div className="trend-report--large">
        <Link to={'/trend-report/' + data.id} className="trend-report--thumbnail--inner">
          {
            data.image ? (
              <LazyImage className="trend-report--thumbnail--image" src={data.image} alt={data.title} aspectRatio={1856/1044} />
            ) : null
          }
          {
            data.is_new ? (
              <div className="trend-report--thumbnail--is-new">{ dictionary.general.new }</div>
            ) : null
          }
          <div className="trend-report--thumbnail--content">
            {
              <div className="trend-report--thumbnail--taxonomy">
                {
                  data.rubric ? (
                    data.rubric.length ? (
                      <span className="trend-report--thumbnail--section">{data.rubric[0].name}</span>
                    ) : (
                      <span className="trend-report--thumbnail--section">{data.rubric.name}</span>
                    )
                  ) : (
                    data.rubrique ? (
                      <span className="trend-report--thumbnail--section">{data.rubrique.name}</span>
                    ) : null
                  )
                }
                {
                  data.keywords ? (
                    Object.keys(data.keywords).map((key, i) => (
                      <span className="trend-report--thumbnail--keyword" key={i}>
                        {data.keywords[key].name}
                      </span>
                    ))
                  ) : null
                }
              </div>
            }
            {
              data.title ? (
                <h3 className="trend-report--thumbnail--title">{data.title}</h3>
              ) : null
            }
            {
              formattedDate ? (
                <time className="trend-report--thumbnail--date" dateTime={data.published_at || data.date}>{formattedDate}</time>
              ) : null
            }
          </div>
        </Link>
      </div>
    )
  } else {
    return (
      <div className="trend-report--thumbnail">
        <Link to={'/trend-report/' + data.id} className="trend-report--thumbnail--inner">
          {
            <div className="trend-report--thumbnail--taxonomy">
              {
                data.rubric ? (
                  data.rubric.length ? (
                    <span className="trend-report--thumbnail--section">{data.rubric[0].name}</span>
                  ) : (
                    <span className="trend-report--thumbnail--section">{data.rubric.name}</span>
                  )
                ) : (
                  data.rubrique ? (
                    <span className="trend-report--thumbnail--section">{data.rubrique.name || data.rubrique}</span>
                  ) : null
                )
              }
              {
                data.keywords ? (
                  Object.keys(data.keywords).map((key, i) => (
                    <span className="trend-report--thumbnail--keyword" key={i}>
                      {data.keywords[key].name || data.keywords[key]}
                    </span>
                  ))
                ) : null
              }
            </div>
          }
          <div className="trend-report--thumbnail--image">
            {
              data.is_new ? (
                <div className="trend-report--thumbnail--is-new">{ dictionary.general.new }</div>
              ) : null
            }
            {
              data.image_thumbnail || data.image ? (
                <LazyImage src={data.image_thumbnail || data.image} alt={data.title} aspectRatio={262/148} />
              ) : null
            }
          </div>
          {
            data.title ? (
              <h3 className="trend-report--thumbnail--title">{data.title}</h3>
            ) : null
          }
          {
            formattedDate ? (
              <time className="trend-report--thumbnail--date" dateTime={data.published_at || data.date}>{formattedDate}</time>
            ) : null
          }
        </Link>
      </div>
    )
  }
}
