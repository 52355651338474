// language selector
import React, { useContext } from 'react'
import classNames from 'classnames'

import { languageOptions } from '../languages'
import { LanguageContext } from '../Contexts/Language'

export default function LanguageSelector(props) {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext)

  // set selected language by calling context method
  const handleLanguageChange = e => userLanguageChange(e.target.dataset.target)

  return (
    <div
      className={classNames('lang-switcher--buttons', {
        'visually-hidden': props.hidden
      })}
    >
      {Object.entries(languageOptions).map(([id, value]) => (
        <button
          type="button"
          key={id}
          data-target={id}
          aria-label={value.fullName}
          onClick={handleLanguageChange}
          className={classNames('btn-unstyled lang-switcher--button', {
            'active': id === userLanguage
          })}
        >
          {value.shortName}
        </button>
      ))}
    </div>
  )
}
