// trend book front page
import React, { useCallback, useContext, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import ContactForm from '../../../Components/ContactForm'
import ExpiredCode from '../../../Components/ExpiredCode'
import Footer from '../../../Components/Footer'
import Nav from '../../../Components/Nav'
import Modal from '../../../Components/Modal'
import RunwayShowThemeItem from '../../../Components/RunwayShowThemeItem'
import Slider from 'react-slick'
import TrendReportGrid from '../../../Components/TrendReportGrid'

import { LanguageContext } from '../../../Contexts/Language'
import { getTrendBook, useAuthState, useAuthDispatch } from '../../../Contexts/MainContext'

import 'slick-carousel/slick/slick.css'

function TrendBookFront(props) {

  const { userLanguage, dictionary } = useContext(LanguageContext)

  const dispatch = useAuthDispatch()
  const { token } = useAuthState()

  const [showContactForm, setShowContactForm] = useState(false)
	const [showContactFormSuccess, setShowContactFormSuccess] = useState(false)
  const [trendBook, setTrendBook] = useState()

  const sliderSettings = {
		responsive: [
			{
				breakpoint: 10000,
				settings: 'unslick'
			},
			{
				breakpoint: 767,
				settings: {
					centerMode: true,
          centerPadding: '32px',
					dots: false,
					arrows: false,
					infinite: false,
				}
			}
		]
  }
  
  const handleGetTrendBook = useCallback(async (id) => {
    try {
      let response = await getTrendBook(dispatch, token, userLanguage, id)
      if (!response) return
      if (response.code === 'OK') {
        setTrendBook(response.data.book)
      }
    } catch (error) {
      console.log(error)
    }
  }, [dispatch, token, userLanguage])

  const handleCloseContactFormSuccess = () => {
		setShowContactFormSuccess(false)
		setShowContactForm(false)
	}

  useEffect(() => {
    if (props.match.params.id) {
      handleGetTrendBook(props.match.params.id)
    }
  }, [props.match.params.id, handleGetTrendBook])

	return (
		<>
			<Helmet>
				<html lang={userLanguage} />
			</Helmet>
			<div className="page trend-books">
				<ExpiredCode />
				<Nav {...props} />
				<div className="main-content">
          {
            trendBook ? (
              <>
                {/* Trend book banner */}
                <div className="trend-book--front--banner">
                  <button
                    type="button"
                    className="arrow-btn arrow-bwd secondary trend-book--front--banner--back"
                    onClick={() => props.history.goBack()}
                  >
                    { dictionary.buttons.back }
                  </button>
                  <div className="trend-book--front--banner--image">
                    <img src={ trendBook.image_couv || trendBook.image } alt={ trendBook.category.name + ' ' + trendBook.title + ' ' + trendBook.season } />
                  </div>
                  {
                    trendBook.image_couv && (
                      <img className="trend-book--front--banner--thumbnail" src={ trendBook.image_couv } alt={ trendBook.category.name + ' ' + trendBook.title + ' ' + trendBook.season } />
                    )
                  }
                  <div className="trend-book--front--banner--content">
                    <div className="trend-book--front--banner--text">
                      <h1 className="trend-book--front--banner--title">
                        { trendBook.title }
                        {
                          trendBook.is_include ? null : (
                            <div className="trend-book--front--banner--not-included">
                              <span>{ dictionary.trendBooks.notIncluded }</span>
                            </div>
                          )
                        }
                      </h1>
                      <div className="trend-book--front--banner--season">
                        { trendBook.season }
                      </div>
                      <div className="trend-book--front--banner--description">
                        { trendBook.desc }
                      </div>
                    </div>
                    {
                      trendBook.is_include ? (
                        <Link to={'/trend-book/' + trendBook.id + '/viewer'} className="btn secondary trend-book--front--banner--button">{ dictionary.trendBooks.readBook}</Link>
                      ) : (
                        <button type="button" className="btn secondary trend-book--front--banner--button" onClick={() => setShowContactForm(true)}>Contact</button>
                      )
                    }
                  </div>
                </div>

                {/* Related trend reports */}
                {
                  trendBook.trend_reports.length ? (
                    <TrendReportGrid title={dictionary.relatedContent.title} items={trendBook.trend_reports} useLarge={false} />
                  ) : null
                }

                {/* Related shows */}
                {
                  trendBook.catwalks.length ? (
                    <div className="category-block runway-show--theme--block">
                      <h2 className="lvl3-title category-block--title runway-show--theme--block--title">{ dictionary.trendBooks.relatedShows }</h2>
                      {
                        trendBook.catwalks.length > 1 ? (
                          <Slider className="category-block--slider" {...sliderSettings}>
                            {
                              Object.keys(trendBook.catwalks).map((key, i) => (
                                <RunwayShowThemeItem key={i} item={trendBook.catwalks[key]} seasonID={trendBook.catwalks[key].season.id} categoryID={trendBook.catwalks[key].category.id} section={trendBook.catwalks[key].rubrique} />
                              ))
                            }
                          </Slider>
                        ) : (
                          <div className="category-block--slider">
                            {
                              Object.keys(trendBook.catwalks).map((key, i) => (
                                <RunwayShowThemeItem key={i} item={trendBook.catwalks[key]} seasonID={trendBook.catwalks[key].season.id} categoryID={trendBook.catwalks[key].category.id} sectionID={trendBook.catwalks[key].rubrique} />
                              ))
                            }
                          </div>
                        )
                      }
                    </div>
                  ) : null
                }
              </>
            ) : null
          }
					<Footer />
				</div>
			</div>
      {
        showContactForm && <ContactForm onClose={() => setShowContactForm(false)} onSuccess={() => setShowContactFormSuccess(true) } />
      }
      {
        showContactFormSuccess && (
          <Modal onClose={handleCloseContactFormSuccess}>
            <div className="modal-icon modal-success">
              <div className="icon" aria-label="✓"></div>
            </div>
            <div className="modal-text">
              { dictionary.contact.success }
            </div>
          </Modal>
        )
      }
		</>
	)
}

export default TrendBookFront
