// home page
import React, { useCallback, useContext, useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { Link, NavLink } from 'react-router-dom'
import Slider from 'react-slick'

import CategoryNav from '../../../Components/CategoryNav'
import ExpiredCode from '../../../Components/ExpiredCode'
import Footer from '../../../Components/Footer'
import LazyImage from '../../../Components/LazyImage'
import Nav from '../../../Components/Nav'
import RunwayShowThemeItem from '../../../Components/RunwayShowThemeItem'
import TrendBookThumbnail from '../../../Components/TrendBookThumbnail'
import TrendReportGrid from '../../../Components/TrendReportGrid'

import { LanguageContext } from '../../../Contexts/Language'
import { getContentPage, useAuthState, useAuthDispatch } from '../../../Contexts/MainContext'

function Home(props) {

	const { userLanguage, dictionary } = useContext(LanguageContext)

  const dispatch = useAuthDispatch()
	const { token, seasons } = useAuthState()

	const [content, setContent] = useState([])

	const homeSliderSettings = {
		adaptiveHeight: true,
		arrows: false,
		autoplay: 4000,
		dots: true,
		infinite: true
	}

	const regularSliderSettings = {
		responsive: [
			{
				breakpoint: 10000,
				settings: 'unslick'
			},
			{
				breakpoint: 767,
				settings: {
					centerMode: true,
					centerPadding: '32px',
					dots: false,
					arrows: false,
					infinite: false,
				}
			}
		]
  }

	const handleGetHomePage = useCallback(async () => {
		try {
			let response = await getContentPage(dispatch, token, userLanguage, 'hp')
			if (!response) return
			if (response.code === 'OK') {
				if (response.data) {
					setContent(response.data)
				} else {
					setContent([])
				}
			}
		} catch (error) {
			console.log(error)
		}
	}, [dispatch, token, userLanguage])

	useEffect(() => {
		handleGetHomePage()
	}, [handleGetHomePage])

	return (
		<>
			<Helmet>
				<html lang={userLanguage} />
			</Helmet>
			<div className="page">
				<ExpiredCode />
				<Nav {...props} />
				<div className="main-content home-page">
					{
						content ? (
							<>
								{
									content.slider_list && content.slider_list.length > 0 ? (
										<Slider className="home-page--slider" {...homeSliderSettings}>
											{
												Object.keys(content.slider_list).map((key, i) => (
													<a
														key={i}
														href={content.slider_list[key].url}
														className="home-page--slide"
														target={content.slider_list[key].is_blank ? "_blank" : "_self"}
														rel={content.slider_list[key].is_blank ? "noreferrer" : null}
													>
														<LazyImage src={content.slider_list[key].image} alt={content.slider_list[key].title} aspectRatio={930/464} />
														<div className="home-page--slide--content">
															<div className="home-page--slide--title">
																{content.slider_list[key].title}
																</div>
															<div className="home-page--slide--desc" dangerouslySetInnerHTML={{__html: content.slider_list[key].description}}></div>
														</div>
													</a>
												))
											}
										</Slider>
									) : null
								}
								{
									content.cdt_list ? (
										<div className="home-page--block home-page--trend-books">
											<div className="lvl2-title home-page--block--title">
												{ dictionary.nav.main.trendBooks }
											</div>
											<div className="category-nav--wrapper">
												<CategoryNav>
													<NavLink to="/trend-books/all" exact className="btn-unstyled nav-link active">{ dictionary.general.all }</NavLink>
													<NavLink to="/trend-books/my-books" className="btn-unstyled nav-link">{ dictionary.trendBooks.myBooks }</NavLink>
													{
														content.cdt_list.categories.length > 0 ? (
															content.cdt_list.categories.map(function(category) {
																return (
																	<NavLink key={category.id} to={'/trend-books/' + category.id} className="btn-unstyled nav-link">
																		{ category.name }
																	</NavLink>
																)
															})
														) : null
													}
												</CategoryNav>
											</div>
											<div className="category-block">
												{
													content.cdt_list.items.length > 1 ? (
														<Slider className="category-block--slider" {...regularSliderSettings}>
															{
																content.cdt_list.items.map(function(trendBook) {
																	return (
																		<TrendBookThumbnail key={trendBook.id} book={trendBook} category={trendBook.category_name} />
																	)
																})
															}
														</Slider>
													) : (
														<div className="category-block--slider">
															{
																content.cdt_list.items.map(function(trendBook) {
																	return (
																		<TrendBookThumbnail key={trendBook.id} book={trendBook} category={trendBook.category_name} />
																	)
																})
															}
														</div>
													)
												}
												<Link to="/trend-books/all" className="arrow-btn arrow-fwd primary category-block--link home-page--block--link">
													{ dictionary.trendBooks.all }
												</Link>
											</div>
										</div>
									) : null
								}
								{
									content.cw_list && seasons && seasons.length ? (
										<div className="home-page--block home-page--runway-shows">
											<div className="lvl2-title home-page--block--title">
												{ dictionary.nav.main.runwayShows }
											</div>
											<div className="category-nav--wrapper">
												<CategoryNav>
													<Link to={"/runway-shows/" + seasons[0].id} className="btn-unstyled nav-link active">{ dictionary.general.all }</Link>
													{
														content.cw_list.categories.length > 0 ? (
															content.cw_list.categories.map(function(category) {
																return (
																	<Link key={category.id} to={'/runway-shows/' + category.season.id + '/' + category.id + '/' + category.rubrique.id} className="btn-unstyled nav-link">
																		{ category.name }
																	</Link>
																)
															})
														) : null
													}
												</CategoryNav>
											</div>
											{
												content.cw_list.items.length > 0 ? (
													<div className="category-block">
														{
															content.cw_list.items.length > 1 ? (
																<Slider className="category-block--slider" {...regularSliderSettings}>
																	{
																		Object.keys(content.cw_list.items).map((key, i) => (
																			<RunwayShowThemeItem key={i} item={content.cw_list.items[key]} seasonID={content.cw_list.items[key].season.id} categoryID={content.cw_list.items[key].category.id} category={content.cw_list.items[key].category.title} section={content.cw_list.items[key].rubrique} />
																		))
																	}
																</Slider>
															) : (
																<div className="category-block--slider">
																	{
																		Object.keys(content.cw_list.items).map((key, i) => (
																			<RunwayShowThemeItem key={i} item={content.cw_list.items[key]} seasonID={content.cw_list.items[key].season.id} categoryID={content.cw_list.items[key].category.id} category={content.cw_list.items[key].category.title} section={content.cw_list.items[key].rubrique} />
																		))
																	}
																</div>
															)
														}
														<Link to={"/runway-shows/" + seasons[0].id} className="arrow-btn arrow-fwd primary category-block--link home-page--block--link">
															{ dictionary.runwayShows.all }
														</Link>
													</div>
												) : null
											}
										</div>
									) : null
								}
								{
									content.tr_list ? (
										<div className="home-page--block home-page--trend-reports">
											<div className="lvl2-title home-page--block--title">
												{ dictionary.nav.main.trendReports }
											</div>
											<div className="category-nav--wrapper">
												<CategoryNav>
													<NavLink to="/trend-reports/all" exact className="btn-unstyled nav-link active">{ dictionary.general.all }</NavLink>
													{
														content.tr_list.categories.length > 0 ? (
															content.tr_list.categories.map(function(category) {
																return (
																	<NavLink key={category.id} to={'/trend-reports/' + category.id} className="btn-unstyled nav-link">
																		{ category.name }
																	</NavLink>
																)
															})
														) : null
													}
												</CategoryNav>
											</div>
											<TrendReportGrid items={content.tr_list.items} useLarge={true} />
											<Link to="/trend-reports/all" className="arrow-btn arrow-fwd primary category-block--link home-page--block--link">
												{ dictionary.trendReports.all }
											</Link>
										</div>
									) : null
								}
							</>
						) : null
					}
					<Footer />
				</div>
			</div>
		</>
	)
}

export default Home
