// request a link to change one's password

import React, { useContext, useState } from 'react'

import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'
import { useFormik } from 'formik'

import { lostPassword, useAuthState, useAuthDispatch } from '../../../Contexts/MainContext'
import { LanguageContext } from '../../../Contexts/Language'

import LanguageSelector from '../../../Components/LanguageSelector'

function RequestPassword() {

	const { userLanguage, dictionary } = useContext(LanguageContext)

	const dispatch = useAuthDispatch()
	const { loading, errorMessage } = useAuthState()

	const [successMessage, setSuccessMessage] = useState('')

	const validate = values => {
		const errors = {}
		if (!values.email) {
			errors.email = dictionary.noEmail
		} else if (!/\b[\w\.-]+@[\w\.-]+\.\w{2,}\b/gi.test(values.email)) {
			errors.email = dictionary.invalidEmail
		}

		return errors
	}

	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validate,
		onSubmit: async (values, actions) => {
			try {
				let response = await lostPassword(dispatch, values)
				if (response.code !== 'OK') return

				if (response.code === 'INVALID_EMAIL') {
					actions.setFieldError('email', dictionary.forms.errors.invalidEmail)
				} else if (response.code === 'MISSING_EMAIL') {
					actions.setFieldError('email', dictionary.forms.errors.noEmail)
				} else if (response.code === 'NOT_FOUND_EMAIL') {
					actions.setFieldError('email', dictionary.forms.errors.noAccount)
				} else if (response.code === 'OK') {
					setSuccessMessage(dictionary.forms.success.requestSuccess)
				}
			} catch (error) {
				console.log(error)
			}
		},
	})

	return (
		<>
			<Helmet>
				<html lang={userLanguage} />
			</Helmet>
			<div className="landing-page">
				<LanguageSelector hidden={true} />
				<div className="login-panel lost-password">
					<form noValidate onSubmit={formik.handleSubmit}>
						{errorMessage ? <div className="panel-addon error">{errorMessage}</div> : null}
						<div className="panel">
							<h1 className="panel-title">{ dictionary.forms.phrases.passwordForgottenQ }</h1>
							<p className="panel-text">
								{ dictionary.forms.phrases.passwordForgottenInstructions }
							</p>
							<div className="form-item">
								<label className="form-label" htmlFor="email">{ dictionary.forms.fields.businessEmail }</label>
								<input
									type="email"
									id="email"
									disabled={loading}
									className={classNames('form-input', {
										'is-invalid': formik.touched.email && formik.errors.email
									})}
									{...formik.getFieldProps('email')}
								/>
								{formik.touched.email && formik.errors.email ? (
									<span className="form-error" aria-live="polite">
										{formik.errors.email}
									</span>
								) : null}
							</div>
							<div className="form-buttons">
								<button type="submit" className="btn primary" disabled={loading}>{ dictionary.forms.actions.send }</button>
							</div>
							{successMessage ? (
								<div className="form-success" aria-live="polite">
									{successMessage}
								</div>
							) : null}
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default RequestPassword
