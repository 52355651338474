import React, { useCallback, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

const useResizeEffect = (onResize, deps = []) => {
  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  // eslint-disable-next-line
  }, [...deps, onResize])
} 

const CategoryNav = ({ children }) => {

  const [container, setContainer] = useState(null)
  const [isScrollable, setIsScrollable] = useState(false)
  const [showEnd, setShowEnd] = useState(true)
  const [showStart, setShowStart] = useState(false)

  const containerRef = useRef(container)
  containerRef.current = container

  const setScrollableOnResize = useCallback(() => {
    if (!containerRef.current) return
    const { clientWidth, scrollWidth } = containerRef.current
    setIsScrollable(scrollWidth > clientWidth)
  }, [containerRef])
  useResizeEffect(setScrollableOnResize, [containerRef])

  useEffect(() => {
    if (!containerRef.current) return
    const onScroll = () => {
      const { scrollWidth, scrollLeft, offsetWidth } =
      containerRef.current
      setShowStart(scrollLeft > 0)
      setShowEnd(scrollLeft + offsetWidth < scrollWidth)
    }
    onScroll()
    const node = containerRef.current
    node.addEventListener('scroll', onScroll)
  // eslint-disable-next-line
  }, [containerRef.current])

  const scroll = (scrollOffset) => {
    containerRef.current.scrollLeft += scrollOffset
  }

  return (
    <div className={'category-nav--container' + (isScrollable ? ' is-scrollable' : '')}>
      <nav className={classNames('category-nav', {
        'show-end': showEnd,
        'show-start': showStart
      })}>
        <button type="button" className="btn-unstyled category-nav--btn left" onClick={() => scroll(-200)}></button>
        <div
          className="category-nav--scroll"
          ref={(element) => {
            if (!element) return
            setContainer(element)
            const { clientWidth, offsetWidth, scrollLeft, scrollWidth } = element
            setIsScrollable(scrollWidth > clientWidth)
            setShowStart(scrollLeft > 0)
            setShowEnd(scrollLeft + offsetWidth < scrollWidth)
          }}
        >
          { children }
        </div>
        <button type="button" className="btn-unstyled category-nav--btn right" onClick={() => scroll(200)}></button>
      </nav>
    </div>
  )

}

export default CategoryNav
