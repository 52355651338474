// edit personal info and password
import React, { useContext, useState } from 'react'

import classNames from 'classnames'
import { Formik } from 'formik'
import Collapsible from 'react-collapsible'

import { LanguageContext } from '../../../Contexts/Language'
import { password, updateUser, useAuthDispatch, useAuthState } from '../../../Contexts/MainContext'

import Modal from '../../../Components/Modal'

function ProfileContent() {

  const { userLanguage, dictionary, userLanguageChange } = useContext(LanguageContext)
  const dispatch = useAuthDispatch()
  const { loading, token, user } = useAuthState()

  const browserLanguage = navigator.language === 'fr-FR' ? 'fr' : 'en'

  const [showEditSuccess, setShowEditSuccess] = useState(false)
  const [showEditError, setShowEditError] = useState(false)

  const [passwordValid, setPasswordValid] = useState(false)

  return (
    <div>
      <div className="profile--content">
        <div className="profile--content--section">
          <Collapsible trigger={ dictionary.profile.profileContent.detailsTitle }>
            <Formik
              enableReinitialize={true}
              initialValues={{
                name: user.name ? user.name : '',
                first_name: user.first_name ? user.first_name : '',
                email_address: user.email_address ? user.email_address : '',
                job_title: user.job_title ? user.job_title : '',
                locale: userLanguage ? userLanguage : browserLanguage
              }}
              validate={values => {
                const errors = {}

                if (!values.email_address) {
                  errors.email_address = dictionary.forms.errors.noEmail
                } else if (!/\b[\w\.-]+@[\w\.-]+\.\w{2,}\b/gi.test(values.email_address)) {
                  errors.email_address = dictionary.forms.errors.invalidEmail
                }
                if (!values.first_name) {
                  errors.first_name = dictionary.forms.errors.noFirstName
                }
                if (!values.name) {
                  errors.name = dictionary.forms.errors.noLastName
                }

                return errors
              }}
              onSubmit={async (values) => {
                try {
                  let response = await updateUser(dispatch, values, token)
                  if (!response) return
                  if (response.code === 'INVALID_EMAIL') {
                    setShowEditError(true)
                  } else if (response.email_address) {
                    userLanguageChange(response.locale)
                    setShowEditSuccess(true)
                  }
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              {({
                dirty,
                errors,
                getFieldProps,
                handleSubmit,
                isValid,
                touched
              }) => (
                <form noValidate className="edit-user-form" onSubmit={handleSubmit}>
                  <div className="form-grid">
                    <div className="form-item">
                      <label className="form-label" htmlFor="name">{ dictionary.forms.fields.lastName }</label>
                      <input
                        type="text"
                        id="name"
                        disabled={loading || user.ambassador_account}
                        className={classNames('form-input', {
                          'is-invalid': touched.name && errors.name
                        })}
                        {...getFieldProps('name')}
                      />
                      {touched.name && errors.name ? (
                        <span className="form-error" aria-live="polite">
                          {errors.name}
                        </span>
                      ) : null}
                    </div>
                    <div className="form-item">
                      <label className="form-label" htmlFor="first_name">{ dictionary.forms.fields.firstName }</label>
                      <input
                        type="text"
                        id="first_name"
                        disabled={loading || user.ambassador_account}
                        className={classNames('form-input', {
                          'is-invalid': touched.first_name && errors.first_name
                        })}
                        {...getFieldProps('first_name')}
                      />
                      {touched.first_name && errors.first_name ? (
                        <span className="form-error" aria-live="polite">
                          {errors.first_name}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-item">
                    <label className="form-label" htmlFor="email_address">{ dictionary.forms.fields.email }</label>
                    <input
                      type="email"
                      id="email_address"
                      disabled={loading || user.ambassador_account}
                      className={classNames('form-input', {
                        'is-invalid': touched.email_address && errors.email_address
                      })}
                      {...getFieldProps('email_address')}
                    />
                    {touched.email_address && errors.email_address ? (
                      <span className="form-error" aria-live="polite">
                        {errors.email_address}
                      </span>
                    ) : null}
                  </div>
                  <div className="form-item">
                    <label className="form-label" htmlFor="job_title">{ dictionary.forms.fields.position }</label>
                    <input
                      type="text"
                      id="job_title"
                      disabled={loading || user.ambassador_account}
                      className={classNames('form-input', {
                        'is-invalid': touched.job_title && errors.job_title
                      })}
                      {...getFieldProps('job_title')}
                    />
                    {touched.job_title && errors.job_title ? (
                      <span className="form-error" aria-live="polite">
                        {errors.job_title}
                      </span>
                    ) : null}
                  </div>
                  <div className="form-item">
                    <label className="form-label" htmlFor="locale">{ dictionary.forms.fields.language }</label>
                    <select
                      id="locale"
                      disabled={loading || user.ambassador_account}
                      className={classNames('form-input', {
                        'is-invalid': touched.locale && errors.locale
                      })}
                      {...getFieldProps('locale')}
                    >
                      <option value="fr">Français</option>
                      <option value="en">English</option>
                    </select>
                    {touched.locale && errors.locale ? (
                      <span className="form-error" aria-live="polite">
                        {errors.locale}
                      </span>
                    ) : null}
                  </div>
                  <div className="form-item">
                    <label className="form-label" htmlFor="company">{ dictionary.forms.fields.affiliatedCompany }</label>
                    <input
                      type="text"
                      id="company"
                      disabled
                      value={user.company.name}
                      className="form-input"
                    />
                  </div>
                  {
                    !user.ambassador_account ? (
                      <div className="form-item">
                        <label className="form-label" htmlFor="job_title">{ dictionary.forms.fields.ambassador }</label>
                        <input
                          type="text"
                          id="ambassador"
                          disabled
                          value={user.ambassador.first_name + ' ' + user.ambassador.name}
                          className="form-input"
                        />
                      </div>
                    ) : null
                  }
                  {
                    !user.ambassador_account ? (
                      <div className="form-buttons">
                        <button type="submit" className="btn primary" disabled={loading || !(isValid && dirty)}>{dictionary.forms.actions.save}</button>
                      </div>
                    ) : null
                  }
                </form>
              )}
            </Formik>
          </Collapsible>
        </div>
        <div className="profile--content--section">
          <Collapsible trigger={ dictionary.profile.profileContent.passwordTitle }>
            <Formik
              initialValues={{
                old_password: '',
                new_password_first: '',
                new_password_second: ''
              }}
              validate={values => {
                const errors = {}

                if (!values.old_password) {
                  errors.old_password = dictionary.forms.errors.noCurrentPassword
                }
                if (!values.new_password_first) {
                  errors.new_password_first = dictionary.forms.errors.noPasswordFirst
                } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/.test(values.new_password_first)) {
                  setPasswordValid(false)
                  errors.new_password_first = dictionary.forms.errors.invalidPassword
                } else {
                  setPasswordValid(true)
                }
                if (!values.new_password_second) {
                  errors.new_password_second = dictionary.forms.errors.noPasswordSecond
                } else if (values.new_password_second !== values.new_password_first) {
                  errors.new_password_second = dictionary.forms.errors.passwordMismatch
                }

                return errors
              }}
              onSubmit={async (values, actions) => {
                try {
                  let response = await password(dispatch, values, token)
                  if (!response) return

                  if (response.length) {
                    if (response[0].code === 'INVALID_OLD_PASSWORD') {
                      actions.setFieldError('old_password', dictionary.forms.errors.invalidCurrentPassword)
                    }
                  } else if (response.code === 'OK') {
                    setShowEditSuccess(!showEditSuccess)
                  }
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              {({
                dirty,
                errors,
                getFieldProps,
                handleSubmit,
                isValid,
                touched
              }) => (
                <form noValidate className="change-password-form" onSubmit={handleSubmit}>
                  <div className="form-item">
                    <label className="form-label" htmlFor="old_password">{ dictionary.forms.fields.oldPassword }</label>
                    <input
                      type="password"
                      id="old_password"
                      disabled={loading}
                      className={classNames('form-input', {
                        'is-invalid': touched.old_password && errors.old_password
                      })}
                      {...getFieldProps('old_password')}
                    />
                    {touched.old_password && errors.old_password ? (
                      <span className="form-error" aria-live="polite">
                        {errors.old_password}
                      </span>
                    ) : null}
                  </div>
                  <div className="form-item">
                    <label className="form-label" htmlFor="new_password_first">{ dictionary.forms.fields.newPassword }</label>
                    <input
                      type="password"
                      id="new_password_first"
                      disabled={loading}
                      className={classNames('form-input', {
                        'is-invalid': touched.new_password_first && errors.new_password_first
                      })}
                      {...getFieldProps('new_password_first')}
                    />
                    {touched.new_password_first && errors.new_password_first ? (
                      <span className="form-error" aria-live="polite">
                        {errors.new_password_first}
                      </span>
                    ) : null}
                    {touched.new_password_first ? (
                      !passwordValid ?
                        <span className="form-help">{ dictionary.forms.phrases.passwordHelp }</span> : <span className="form-success">{ dictionary.forms.phrases.passwordSecure }</span>
                      ) : null
                    }
                  </div>
                  <div className="form-item">
                    <label className="form-label" htmlFor="new_password_second">{ dictionary.forms.fields.repeatPassword }</label>
                    <input
                      type="password"
                      id="new_password_second"
                      disabled={loading}
                      className={classNames('form-input', {
                        'is-invalid': touched.new_password_second && errors.new_password_second
                      })}
                      {...getFieldProps('new_password_second')}
                    />
                    {touched.new_password_second && errors.new_password_second ? (
                      <span className="form-error" aria-live="polite">
                        {errors.new_password_second}
                      </span>
                    ) : null}
                  </div>
                  <div className="form-buttons">
                    <button type="submit" className="btn primary" disabled={loading || !(isValid && dirty)}>{dictionary.forms.actions.save}</button>
                  </div>
                </form>
              )}
            </Formik>
          </Collapsible>
        </div>
        {
          showEditError ? (
            <Modal onClose={() => setShowEditError(false)}>
              <div className="modal-icon modal-error">
                <div className="icon" aria-label="!"></div>
              </div>
              <div className="modal-text">
                { dictionary.forms.errors.emailExists }
              </div>
            </Modal>
          ) : null
        }
        {
          showEditSuccess ? (
            <Modal onClose={() => setShowEditSuccess(false)}>
              <div className="modal-icon modal-success">
                <div className="icon" aria-label="✓"></div>
              </div>
              <div className="modal-text">
                { dictionary.profile.editSuccess.ok }
              </div>
            </Modal>
          ) : null
        }
      </div>
    </div>
  )
}

export default ProfileContent
