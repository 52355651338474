import React from 'react'

import { createBrowserHistory } from 'history'
import { HelmetProvider } from 'react-helmet-async'
import { Router, Switch } from 'react-router-dom'
import Div100vh from 'react-div-100vh'
import ReactGA from 'react-ga'

import AppRoute from './Components/AppRoute'
import routes from './Config/routes.js'

import { AuthProvider } from './Contexts/MainContext'
import { LanguageProvider } from './Contexts/Language'

import './assets/styles/app.scss'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }

    return this.props.children 
  }
}

function App() {

	ReactGA.initialize('UA-97089443-1')

	const history = createBrowserHistory()

	history.listen(location => {
		ReactGA.set({ page: location.pathname })
		ReactGA.pageview(location.pathname)
	})

	return (
		<ErrorBoundary>
			<HelmetProvider>
				<AuthProvider>
					<LanguageProvider>
						<Div100vh>
							<Router history={history}>
								<Switch>
									{routes.map((route) => (
										<AppRoute
											key={route.path}
											exact={route.exact}
											path={route.path}
											component={route.component}
											isPrivate={route.isPrivate}
										/>
									))}
								</Switch>
							</Router>
						</Div100vh>
					</LanguageProvider>
				</AuthProvider>
			</HelmetProvider>
		</ErrorBoundary>
	)
}

export default App
