// pop-in window
import React, { useContext } from 'react'

import classNames from 'classnames'

import { LanguageContext } from '../Contexts/Language'

const Modal = ({ children, errorMessage = null, onClose, largeModal = false }) => {

  const { dictionary } = useContext(LanguageContext)

  return (
    <div className="modal show">
      <div className={classNames('modal-dialog modal-animate-top', { 'large': largeModal } )}>
        {errorMessage ? <div className="panel-addon error">{errorMessage}</div> : null}
        <div className="modal-panel">
          <button type="button" onClick={onClose} className="btn-unstyled close-btn" aria-label={ dictionary.buttons.close }></button>
          <div className="modal-content">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
