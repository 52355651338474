// trend books hub / list page
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'

import debounce from 'lodash.debounce'
import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom'
import Slider from 'react-slick'

import CategoryNav from '../../../Components/CategoryNav'
import ExpiredCode from '../../../Components/ExpiredCode'
import Footer from '../../../Components/Footer'
import Header from '../../../Components/Header'
import Nav from '../../../Components/Nav'
import TrendBookThumbnail from '../../../Components/TrendBookThumbnail'

import useOnScreen from '../../../Utilities/useOnScreen'

import { LanguageContext } from '../../../Contexts/Language'
import { getMyTrendBooks, getTrendBookCategories, getTrendBooksByCategory, getTrendBookHub, useAuthState, useAuthDispatch } from '../../../Contexts/MainContext'

import 'slick-carousel/slick/slick.css'

function TrendBookList(props) {

  const { userLanguage, dictionary } = useContext(LanguageContext)

  const dispatch = useAuthDispatch()
  const { token } = useAuthState()

  const [displayHub, setDisplayHub] = useState(false)
  const [displayList, setDisplayList] = useState(false)

  const [categories, setCategories] = useState([])
  const [currentCategoryName, setCurrentCategoryName] = useState(null)
  const [hasMore, setHasMore] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [nextPage, setNextPage] = useState()
  const [trendBookHub, setTrendBookHub] = useState([])
  const [trendBookList, setTrendBookList] = useState([])
  
  const pager = useRef()
  const isVisible = useOnScreen(pager)

  const items_per_page = 10

	const sliderSettings = {
		responsive: [
			{
				breakpoint: 10000,
				settings: 'unslick'
			},
			{
				breakpoint: 767,
				settings: {
					centerMode: true,
          centerPadding: '32px',
					dots: false,
					arrows: false,
					infinite: false,
				}
			}
		]
  }

  const handleGetCategories = useCallback(async () => {
    try {
      let response = await getTrendBookCategories(dispatch, token, userLanguage)
      if (!response) return
      if (response.code === 'OK') {
        setCategories(response.data.categories)
      }
    } catch (error) {
      console.log(error)
    }
  }, [dispatch, token, userLanguage])

  const handleGetHub = useCallback(async () => {
    try {
      let response = await getTrendBookHub(dispatch, token, userLanguage)
      if (!response) return
      if (response.code === 'OK') {
        setCurrentCategoryName(null)
        setTrendBookHub(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }, [dispatch, token, userLanguage])

  const handleGetMyBooks = useCallback(async (page) => {
    let payload = {
      "per_page": items_per_page,
      "page": page,
    }
    try {
      setIsLoading(true)
      if (page === 0) {
        setTrendBookList([])
      }
      let response = await getMyTrendBooks(dispatch, token, userLanguage, payload)
      if (!response) return
      if (response.code === 'OK') {
        setIsLoading(false)
        if (response.data.books.length) {
          setCurrentCategoryName('My books')
          const newList = Object.values(response.data.books)
          setTrendBookList(trendBookList => trendBookList.concat(newList))
          setNextPage(page + 1)
          if (response.data.books.length < items_per_page) {
            setHasMore(false)
          }
        } else {
          setHasMore(false)
        }
      }
      
    } catch (error) {
      console.log(error)
    }
  }, [dispatch, token, userLanguage])

  const handleGetTrendBookList = useCallback(async (id, page) => {
    let payload = {
      "category": id,
      "per_page": items_per_page,
      "page": page,
    }
    try {
      setIsLoading(true)
      if (page === 0) {
        setTrendBookList([])
      }
      let response = await getTrendBooksByCategory(dispatch, token, userLanguage, payload)
      if (!response) return
      if (response.code === 'OK') {
        setIsLoading(false)
        if (response.data.books.length) {
          setCurrentCategoryName(response.data.books[0].category)
          const newList = Object.values(response.data.books)
          setTrendBookList(trendBookList => trendBookList.concat(newList))
          setNextPage(page + 1)
          if (response.data.books.length < items_per_page) {
            setHasMore(false)
          }
        } else {
          setHasMore(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }, [dispatch, token, userLanguage])

  const loadMore = () => {
    if (props.match.params.id === 'my-books') {
      handleGetMyBooks(nextPage)
    } else if (props.match.params.id !== null && props.match.params.id !== undefined) {
      handleGetTrendBookList(props.match.params.id, nextPage)
    }
  }

  const handleScroll = debounce(() => {
    if (isLoading || !hasMore || props.match.params.id === 'all') return
    if (isVisible) {
      loadMore()
    }
  }, 500)

  useEffect(() => {
    handleGetCategories()
  }, [handleGetCategories])

  useEffect(() => {
    setHasMore(true)
    setTrendBookList([])
    setTrendBookHub([])
    if (props.match.params.id === 'all') {
      handleGetHub()
    } else if (props.match.params.id === 'my-books') {
      handleGetMyBooks(0)
    } else if (props.match.params.id !== null && props.match.params.id !== undefined) {
      handleGetTrendBookList(props.match.params.id, 0)
    }
  }, [userLanguage, props.match.params.id, handleGetHub, handleGetMyBooks, handleGetTrendBookList])

  useEffect(() => {
    if (trendBookHub.length) {
      setDisplayList(false)
      setDisplayHub(true)
    }
  }, [trendBookHub])

  useEffect(() => {
    if (trendBookList.length) {
      setDisplayHub(false)
      setDisplayList(true)
    }
  }, [trendBookList])

	return (
		<>
			<Helmet>
				<html lang={userLanguage} />
			</Helmet>
			<div className="page trend-books">
				<ExpiredCode />
				<Nav {...props} />
				<div className="main-content" onScroll={handleScroll}>
					<Header title={ dictionary.nav.main.trendBooks } text={ dictionary.trendBooks.intro } />
          <div className="category-nav--wrapper">
            <CategoryNav>
              <NavLink to="/trend-books/all" exact className="btn-unstyled nav-link">{ dictionary.general.all }</NavLink>
              <NavLink to="/trend-books/my-books" className="btn-unstyled nav-link">{ dictionary.trendBooks.myBooks }</NavLink>
              {
                categories.length > 0 ? (
                  categories.map(function(category) {
                    return (
                      <NavLink key={category.id} to={'/trend-books/' + category.id} className="btn-unstyled nav-link">
                        { category.name }
                      </NavLink>
                    )
                  })
                ) : null
              }
            </CategoryNav>
          </div>
          {
            displayHub ? (
              trendBookHub.map(function(category) {
                return (
                  <div className="category-block" key={category.id}>
                    <h2 className="lvl2-title category-block--title">{ category.name }</h2>
                    {
                      category.books.length > 1 ? (
                        <Slider className="category-block--slider" {...sliderSettings}>
                          {
                            category.books.map(function(trendBook) {
                              return (
                                <TrendBookThumbnail key={trendBook.id} book={trendBook} />
                              )
                            })
                          }
                        </Slider>
                      ) : (
                        <div className="category-block--slider">
                          {
                            category.books.map(function(trendBook) {
                              return (
                                <TrendBookThumbnail key={trendBook.id} book={trendBook} />
                              )
                            })
                          }
                        </div>
                      )
                    }
                    <a href={'/trend-books/' + category.id} className="arrow-btn arrow-fwd primary category-block--link">
                      { dictionary.trendBooks.allSeasons }
                    </a>
                  </div>
                )
              })
            ) : null
          }
          {
            displayList ? (
              <div className="trend-book--list">
                {
                  currentCategoryName ? (
                    <h2 className="lvl2-title">{currentCategoryName}</h2>
                  ) : (
                    <h2 className="lvl2-title">My books</h2>
                  )
                }
                <div className="trend-book--list--grid">
                  {
                    trendBookList.map(function(trendBook) {
                      return (
                        <TrendBookThumbnail key={trendBook.id} book={trendBook} />
                      )
                    })
                  }
                </div>
              </div>
            ) : null
          }
          <div ref={pager}></div>
					<Footer />
				</div>
			</div>
		</>
	)
}

export default TrendBookList
