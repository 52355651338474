// contact form
import React, { useContext, useState } from 'react'

import classNames from 'classnames'
import { useFormik } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'

import Modal from '../Components/Modal'

import { contactRequest, useAuthState, useAuthDispatch } from '../Contexts/MainContext'
import { LanguageContext } from '../Contexts/Language'

const ContactForm = ({ onClose, onSuccess }) => {

  const { userLanguage, dictionary } = useContext(LanguageContext)

	const dispatch = useAuthDispatch()
  const { loading, token } = useAuthState()

  const countryList = dictionary.forms.fields.countries
  const industryList = dictionary.forms.fields.industries
  const functionList = dictionary.forms.fields.positions

  const [contactFormError, setContactFormError] = useState()

  const formik = useFormik({
		initialValues: {
      civility: '',
      lastname: '',
      firstname: '',
      email: '',
      country: '',
      phone: '',
      company: '',
      industry: '',
      function: '',
      message: '',
      recaptcha: ''
		},
		validate: values => {
			const errors = {}
			if (!values.civility) {
				errors.civility = dictionary.forms.errors.noCivility
			}
			if (!values.lastname) {
				errors.lastname = dictionary.forms.errors.noLastName
			}
			if (!values.firstname) {
				errors.firstname = dictionary.forms.errors.noFirstName
			}
			if (!values.email) {
				errors.email = dictionary.forms.errors.noEmail
			} else if (!/\b[\w\.-]+@[\w\.-]+\.\w{2,}\b/gi.test(values.email)) {
				errors.email = dictionary.forms.errors.invalidEmail
			}
			if (!values.country) {
				errors.country = dictionary.forms.errors.noCountry
			}
			if (!values.phone) {
				errors.phone = dictionary.forms.errors.noPhone
			}
			if (!values.industry) {
				errors.industry = dictionary.forms.errors.noIndustry
			}
			if (!values.function) {
				errors.function = dictionary.forms.errors.noPosition
			}
      if (values.message.length > 255) {
				errors.message = 255 - values.message.length
			}
			if (!token && !values.recaptcha) {
				errors.recaptcha = dictionary.forms.errors.recaptcha
			}

			return errors
		},
		onSubmit: async (values) => {
      delete values.recaptcha
			try {
        let response = await contactRequest(dispatch, values, userLanguage)
        if (!response) return
        if (response.code === 'OK') {
          setContactFormError(null)
          onSuccess()
        } else {
          setContactFormError(response.message)
        }
			} catch (error) {
				console.log(error)
			}
		},
	})

  const onCaptchaChange = (value) => {
    formik.setFieldValue('recaptcha', value)
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Modal onClose={handleClose}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <div className="modal-title">
          { dictionary.contact.title }

          <div className="modal-subtitle opacity">
            { dictionary.contact.text }
          </div>
        </div>
        <div className="form-item">
          <label className="form-label" htmlFor="civility">{dictionary.forms.fields.civility}</label>
          <select
            id="civility"
            disabled={loading}
            className={classNames('form-input', {
              'is-invalid': formik.touched.civility && formik.errors.civility
            })}
            {...formik.getFieldProps('civility')}
          >
            <option value="">…</option>
            <option value={dictionary.forms.fields.mister}>{dictionary.forms.fields.mister}</option>
            <option value={dictionary.forms.fields.mistress}>{dictionary.forms.fields.mistress}</option>
            <option value="Dr.">Dr.</option>
            <option value="Prof.">Prof.</option>
          </select>
          {formik.touched.civility && formik.errors.civility ? (
            <span className="form-error" aria-live="polite">
              {formik.errors.civility}
            </span>
          ) : null}
        </div>
        <div className="form-grid">
          <div className="form-item">
            <label className="form-label" htmlFor="lastname">{dictionary.forms.fields.lastName}</label>
            <input
              type="text"
              id="lastname"
              disabled={loading}
              className={classNames('form-input', {
                'is-invalid': formik.touched.lastname && formik.errors.lastname
              })}
              {...formik.getFieldProps('lastname')}
            />
            {formik.touched.lastname && formik.errors.lastname ? (
              <span className="form-error" aria-live="polite">
                {formik.errors.lastname}
              </span>
            ) : null}
          </div>
          <div className="form-item">
            <label className="form-label" htmlFor="firstname">{dictionary.forms.fields.firstName}</label>
            <input
              type="text"
              id="firstname"
              disabled={loading}
              className={classNames('form-input', {
                'is-invalid': formik.touched.firstname && formik.errors.firstname
              })}
              {...formik.getFieldProps('firstname')}
            />
            {formik.touched.firstname && formik.errors.firstname ? (
              <span className="form-error" aria-live="polite">
                {formik.errors.firstname}
              </span>
            ) : null}
          </div>
        </div>
        <div className="form-item">
          <label className="form-label" htmlFor="email">{dictionary.forms.fields.businessEmail}</label>
          <input
            type="email"
            id="email"
            disabled={loading}
            className={classNames('form-input', {
              'is-invalid': formik.touched.email && formik.errors.email
            })}
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <span className="form-error" aria-live="polite">
              {formik.errors.email}
            </span>
          ) : null}
        </div>
        <div className="form-item">
          <label className="form-label" htmlFor="country">{dictionary.forms.fields.country}</label>
          <select
            id="country"
            disabled={loading}
            className={classNames('form-input', {
              'is-invalid': formik.touched.country && formik.errors.country
            })}
            {...formik.getFieldProps('country')}
          >
            <option value="">…</option>
            {
              Object.keys(countryList).map((key, i) => (
                <option key={i} value={countryList[key]}>{countryList[key]}</option>
              ))
            }
          </select>
          {formik.touched.country && formik.errors.country ? (
            <span className="form-error" aria-live="polite">
              {formik.errors.country}
            </span>
          ) : null}
        </div>
        <div className="form-item">
          <label className="form-label" htmlFor="phone">{dictionary.forms.fields.phone}</label>
          <input
            type="tel"
            id="phone"
            maxLength="15"
            disabled={loading}
            className={classNames('form-input', {
              'is-invalid': formik.touched.phone && formik.errors.phone
            })}
            {...formik.getFieldProps('phone')}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <span className="form-error" aria-live="polite">
              {formik.errors.phone}
            </span>
          ) : null}
        </div>
        <div className="form-item">
          <label className="form-label" htmlFor="company">{dictionary.forms.fields.company}</label>
          <input
            type="text"
            id="company"
            disabled={loading}
            className={classNames('form-input', {
              'is-invalid': formik.touched.company && formik.errors.company
            })}
            {...formik.getFieldProps('company')}
          />
          {formik.touched.company && formik.errors.company ? (
            <span className="form-error" aria-live="polite">
              {formik.errors.company}
            </span>
          ) : null}
        </div>
        <div className="form-item">
          <label className="form-label" htmlFor="industry">{dictionary.forms.fields.industry}</label>
          <select
            id="industry"
            disabled={loading}
            className={classNames('form-input', {
              'is-invalid': formik.touched.industry && formik.errors.industry
            })}
            {...formik.getFieldProps('industry')}
          >
            <option value="">…</option>
            {
              Object.keys(industryList).map((key, i) => (
                <option key={i} value={industryList[key]}>{industryList[key]}</option>
              ))
            }
          </select>
          {formik.touched.industry && formik.errors.industry ? (
            <span className="form-error" aria-live="polite">
              {formik.errors.industry}
            </span>
          ) : null}
        </div>
        <div className="form-item">
          <label className="form-label" htmlFor="function">{dictionary.forms.fields.position}</label>
          <select
            id="function"
            disabled={loading}
            className={classNames('form-input', {
              'is-invalid': formik.touched.function && formik.errors.function
            })}
            {...formik.getFieldProps('function')}
          >
            <option value="">…</option>
            {
              Object.keys(functionList).map((key, i) => (
                <option key={i} value={functionList[key]}>{functionList[key]}</option>
              ))
            }
          </select>
          {formik.touched.function && formik.errors.function ? (
            <span className="form-error" aria-live="polite">
              {formik.errors.function}
            </span>
          ) : null}
        </div>
        <div className="form-item">
          <label className="form-label" htmlFor="message">{dictionary.forms.fields.comments}</label>
          <textarea
            id="message"
            disabled={loading}
            rows="5"
            className={classNames('form-input', {
              'is-invalid': formik.touched.message && formik.errors.message
            })} 
            {...formik.getFieldProps('message')}
          ></textarea>
          {formik.touched.message && formik.errors.message ? (
            <span className="form-error" aria-live="polite">
              {formik.errors.message}
            </span>
          ) : null}
        </div>
        {
          token ? (
            null
          ) : (
            <div className="form-item">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={onCaptchaChange}
              />
              {formik.errors.recaptcha && formik.touched.recaptcha && (
                <span className="form-error" aria-live="polite">
                  {formik.errors.recaptcha}
                </span>
              )}
            </div>
          )
        }
        <div className="form-buttons single-centered">
          <button type="submit" className="btn primary ghost" disabled={loading}>{dictionary.forms.actions.send}</button>
        </div>
        {contactFormError ? <div className="form-error text-center" aria-live="polite">{contactFormError}.</div> : null}
        <p className="legal-mentions">
          {dictionary.forms.phrases.legal}
        </p>
      </form>
    </Modal>
  )
}

export default ContactForm
