import React, { useContext, useEffect, useState } from 'react'

import classNames from 'classnames'

import LazyImage from '../../../../../Components/LazyImage'
import DownloadButton from '../../../../../Components/DownloadButton'

import { LanguageContext } from '../../../../../Contexts/Language'

function ImageItem({ selectImage, deSelectImage, expandImage, unCheckAll, imageData }) {

	const { dictionary } = useContext(LanguageContext)

	const [isExpanded, setIsExpanded] = useState(false)
  const [isSelected, setIsSelected] = useState(false)

	const toggleSelect = (e) => {
    if (!isSelected) {
      setIsSelected(true)
      selectImage(parseInt(e.target.dataset.id))
    } else {
      setIsSelected(false)
      deSelectImage(parseInt(e.target.dataset.id))
    }
  }

	const toggleExpand = (e) => {
    expandImage(e.target.dataset.imageurl)
	}

  useEffect(() => {
    if (imageData) {
      setIsSelected(false)
    }
  }, [imageData])

  useEffect(() => {
    if (unCheckAll) {
      setIsSelected(false)
    }
  }, [unCheckAll])

	return (
		<div className="pdf-viewer--downloads--image-item">
			<LazyImage src={imageData.thumbnail} alt={''} />
      {
        imageData.image && (
          <>
            <div className="pdf-viewer--downloads--image-item--overlay">
              <button
                type="button"
                className={classNames('btn-unstyled pdf-viewer--downloads--image-item--btn-select', {'active': isSelected})}
                data-id={imageData.id}
                onClick={toggleSelect}
              >{dictionary.buttons.select}</button>
              <button
                type="button"
                className="btn-unstyled pdf-viewer--downloads--image-item--btn-expand"
                data-imageurl={imageData.image}
                onClick={toggleExpand}
              ><span className="sr-only">{dictionary.buttons.expand}</span></button>
              <DownloadButton
                cssClasses={"btn-unstyled pdf-viewer--downloads--image-item--btn-download"}
                url={imageData.image}
                filename={imageData.image.split('\\').pop().split('/').pop()}
              />
            </div>
          </>
        )
      }
		</div>
	)
}

export default ImageItem
