// home page
import React, { useCallback, useContext, useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'

import ExpiredCode from '../../Components/ExpiredCode'
import Footer from '../../Components/Footer'
import Nav from '../../Components/Nav'

import { LanguageContext } from '../../Contexts/Language'
import { getContentPage, useAuthState, useAuthDispatch } from '../../Contexts/MainContext'

function Terms(props) {

	const { userLanguage } = useContext(LanguageContext)

  const dispatch = useAuthDispatch()
	const { token } = useAuthState()

	const [content, setContent] = useState([])

	const handleGetTermsPage = useCallback(async () => {
		try {
			let response = await getContentPage(dispatch, token, userLanguage, 'terms')
			if (!response) return
			if (response.code === 'OK') {
				if (response.data) {
					setContent(response.data)
				} else {
					setContent([])
				}
			}
		} catch (error) {
			console.log(error)
		}
	}, [dispatch, token, userLanguage])

	useEffect(() => {
		handleGetTermsPage()
	}, [handleGetTermsPage])

	return (
		<>
			<Helmet>
				<html lang={userLanguage} />
			</Helmet>
			<div className="page">
				<ExpiredCode />
				<Nav {...props} />
				<div className="main-content static-page">
					{
						content ? (
              <div className="static-page--body">
                <h1 className="static-page--lvl1-title">{content.title}</h1>
                <div className="static-page--content" dangerouslySetInnerHTML={{__html: content.content}}></div>
              </div>
						) : null
					}
					<Footer />
				</div>
			</div>
		</>
	)
}

export default Terms
