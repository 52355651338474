// trend books hub page
import React, { useCallback, useContext, useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'

import ExpiredCode from '../../../Components/ExpiredCode'
import Footer from '../../../Components/Footer'
import Nav from '../../../Components/Nav'
import TrendReportGrid from '../../../Components/TrendReportGrid'

import { LanguageContext } from '../../../Contexts/Language'
import { getTrendReport, useAuthState, useAuthDispatch } from '../../../Contexts/MainContext'

function TrendReportArticle(props) {

  const { userLanguage, dictionary } = useContext(LanguageContext)
  
  const dispatch = useAuthDispatch()
  const { token } = useAuthState()

  const [trendReport, setTrendReport] = useState()

  let dateConfig

  if (userLanguage === 'fr') {
    dateConfig = { year: 'numeric', month: 'long', day: 'numeric' }
  } else {
    dateConfig = { year: 'numeric', month: 'short', day: 'numeric' }
  }

  const handleGetTrendReport = useCallback(async (id) => {
    try {
      let response = await getTrendReport(dispatch, token, userLanguage, id)
      if (!response) return
      if (response.code === 'OK') {
        setTrendReport(response.data.trend)
      }
    } catch (error) {
      console.log(error)
    }
  }, [dispatch, token, userLanguage])

  useEffect(() => {
    if (props.match.params.id) {
      handleGetTrendReport(props.match.params.id)
    }
  }, [props.match.params.id, handleGetTrendReport])

	return (
    <>
			<Helmet>
				<html lang={userLanguage} />
			</Helmet>
			<div className="page trend-report--article-page">
				<ExpiredCode />
				<Nav {...props} />
				<div className="main-content">
          {
            trendReport ? (
              <>
                <article className="trend-report--article">
                  <button
                    type="button"
                    className="arrow-btn arrow-bwd primary trend-report--article--btn-back"
                    onClick={() => props.history.goBack()}
                  >
                    { dictionary.buttons.back }
                  </button>
                  <header className="trend-report--article--header">
                    <h1 className="trend-report--article--title">
                      { trendReport.title }
                    </h1>
                    <div className="trend-report--article--date-author">
                      <time className="trend-report--article--date" dateTime={trendReport.published_at}>
                        {                      
                          new Date(trendReport.published_at.replace(' ', 'T')).toLocaleDateString(userLanguage, dateConfig).replace(/\//g, '.')
                        }
                      </time>
                      <span className="trend-report--article--author">
                        &nbsp;—&nbsp;{ trendReport.author }
                      </span>
                    </div>
                  </header>
                  <div className="trend-report--article--content" dangerouslySetInnerHTML={{__html: trendReport.content}}></div>
                </article>
                <TrendReportGrid title={dictionary.relatedContent.title} items={trendReport.associated_tr} useLarge={false} />
              </>
            ) : null
          }
          <Footer />
        </div>
      </div>
    </>
	)
}

export default TrendReportArticle
