// account validation for invited users
import React, { useContext, useEffect, useState } from 'react'

import classNames from 'classnames'
import { Formik } from 'formik'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import { getRegistration, getRunwayShowSeasons, getUser, loginUser, useAuthState, useAuthDispatch, validateUser } from '../../../Contexts/MainContext'
import { LanguageContext } from '../../../Contexts/Language'

import ContactForm from '../../../Components/ContactForm'
import LanguageSelector from '../../../Components/LanguageSelector'
import Logo from '../../../Components/Logo'
import Modal from '../../../Components/Modal'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function Validation(props) {

  const { userLanguage, dictionary } = useContext(LanguageContext)

	const dispatch = useAuthDispatch()
  const { user, loading, errorMessage } = useAuthState()

  const pw_token = useQuery().get('pw_token')

  const [showContactForm, setShowContactForm] = useState(false)
	const [showContactFormSuccess, setShowContactFormSuccess] = useState(false)
  const [showTokenExpired, setShowTokenExpired] = useState(false)
  const [showValidationSuccess, setShowValidationSuccess] = useState(false)

  const [company, setCompany] = useState('')
  const [isAmbassador, setIsAmbassador] = useState(false)
  const [initialValues, setInitialValues] = useState()
  const [passwordValid, setPasswordValid] = useState(false)

  const closeValidationSuccess = () => {
    setShowValidationSuccess(!showValidationSuccess)
    props.history.push('/')
  }

  const handleCloseContactFormSuccess = () => {
		setShowContactFormSuccess(false)
		setShowContactForm(false)
	}

  useEffect(() => {
    async function handleGetRegistration() {
      try {
        let response = await getRegistration(dispatch, pw_token)
        if (!response) return
        if (response.code === 'EXPIRED_PASSWORD_TOKEN') {
          setShowTokenExpired(true)
        } else {
          setInitialValues({
            pw_token: response.pw_token ? response.pw_token : '',
            name: response.name ? response.name : '',
            first_name: response.first_name ? response.first_name : '',
            email_address: response.email_address ? response.email_address : '',
            job_title: response.job_title ? response.job_title : '',
            password_first: '',
            password_second: '',
            locale: response.locale ? response.locale : ''
          })
          if (response.ambassador) {
            setIsAmbassador(true)

            if (response.company.name) {
              setCompany(response.company.name)
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    handleGetRegistration()
  }, [pw_token, dispatch])

  return (
    <>
      <Helmet>
        <html lang={userLanguage} />
      </Helmet>
      <div className="landing-page">
        <LanguageSelector hidden={true} />
        {
          initialValues ? (
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validate={values => {
                const errors = {}
                if (!values.first_name) {
                  errors.first_name = dictionary.forms.errors.noFirstName
                }
                if (!values.name) {
                  errors.name = dictionary.forms.errors.noLastName
                }
                if (!values.email_address) {
                  errors.email_address = dictionary.forms.errors.noEmail
                } else if (!/\b[\w\.-]+@[\w\.-]+\.\w{2,}\b/gi.test(values.email_address)) {
                  errors.email_address = dictionary.forms.errors.invalidEmail
                }
                if (!values.password_first) {
                  errors.password_first = dictionary.forms.errors.noPasswordFirst
                } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/.test(values.password_first)) {
                  setPasswordValid(false)
                  errors.password_first = dictionary.forms.errors.invalidPassword
                } else {
                  setPasswordValid(true)
                }
                if (!values.password_second) {
                  errors.password_second = dictionary.forms.errors.noPasswordSecond
                } else if (values.password_second !== values.password_first) {
                  errors.password_second = dictionary.forms.errors.passwordMismatch
                }

                return errors
              }}
              onSubmit={async (values) => {
                try {
                  let validateUserResponse = await validateUser(dispatch, values)
                  if (!validateUserResponse.code) return

                  if (validateUserResponse.code === 'OK') {
                    let loginResponse = await loginUser(dispatch, { email: values.email_address, password: values.password_first })
                    if (!loginResponse.token) return

                    let getUserResponse = await getUser(dispatch, loginResponse.token)
                    if (!getUserResponse.id) return

                    setShowValidationSuccess(!showValidationSuccess)

                    try {
                      let getSeasonsResponse = await getRunwayShowSeasons(dispatch, loginResponse.token, userLanguage)
                      if (getSeasonsResponse.code !== 'OK') return
                    } catch (error) {
                      console.log(error)
                    }
                  }
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              {({
                errors,
                getFieldProps,
                handleSubmit,
                touched
              }) => (
                <form noValidate className="register-form" onSubmit={handleSubmit}>
                  {errorMessage ? <div className="panel-addon error">{errorMessage}</div> : null}
                  <div className="panel side-panel">
                    <h1 className="form-logo">
                      <Logo />
                    </h1>
                    <div className="form-grid">
                      <div className="form-item">
                        <label className="form-label" htmlFor="name">{ dictionary.forms.fields.lastName }*</label>
                        <input
                          type="text"
                          id="name"
                          disabled={isAmbassador || loading}
                          className={classNames('form-input', {
                            'is-invalid': touched.name && errors.name
                          })}
                          {...getFieldProps('name')}
                        />
                        {touched.name && errors.name ? (
                          <span className="form-error" aria-live="polite">
                            {errors.name}
                          </span>
                        ) : null}
                      </div>
                      <div className="form-item">
                        <label className="form-label" htmlFor="first_name">{ dictionary.forms.fields.firstName }*</label>
                        <input
                          type="text"
                          id="first_name"
                          disabled={isAmbassador || loading}
                          className={classNames('form-input', {
                            'is-invalid': touched.first_name && errors.first_name
                          })}
                          {...getFieldProps('first_name')}
                        />
                        {touched.first_name && errors.first_name ? (
                          <span className="form-error" aria-live="polite">
                            {errors.first_name}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-item">
                      <label className="form-label" htmlFor="email_address">{ dictionary.forms.fields.businessEmail }*</label>
                      <input
                        type="email"
                        id="email_address"
                        disabled={isAmbassador || loading}
                        className={classNames('form-input', {
                          'is-invalid': touched.email_address && errors.email_address
                        })}
                        {...getFieldProps('email_address')}
                      />
                      {touched.email_address && errors.email_address ? (
                        <span className="form-error" aria-live="polite">
                          {errors.email_address}
                        </span>
                      ) : null}
                    </div>
                    <div className="form-item">
                      <label className="form-label" htmlFor="job_title">{ dictionary.forms.fields.position }</label>
                      <input
                        type="text"
                        id="job_title"
                        disabled={loading}
                        className="form-input"
                        {...getFieldProps('job_title')}
                      />
                    </div>
                    <div className="form-item">
                      <label className="form-label" htmlFor="password_first">{ dictionary.forms.fields.password }*</label>
                      <input
                        type="password"
                        id="password_first"
                        disabled={loading}
                        className={classNames('form-input', {
                          'is-invalid': touched.password_first && errors.password_first
                        })}
                        {...getFieldProps('password_first')}
                      />
                      {touched.password_first && errors.password_first ? (
                        <span className="form-error" aria-live="polite">
                          {errors.password_first}
                        </span>
                      ) : null}
                      {touched.password_first ? (
                        !passwordValid ?
                          <span className="form-help">{ dictionary.forms.phrases.passwordHelp }</span> : <span className="form-success">{ dictionary.forms.phrases.passwordSecure }</span>
                        ) : null
                      }
                    </div>
                    <div className="form-item">
                      <label className="form-label" htmlFor="password_second">{ dictionary.forms.fields.checkPassword }*</label>
                      <input
                        type="password"
                        id="password_second"
                        disabled={loading}
                        className={classNames('form-input', {
                          'is-invalid': touched.password_second && errors.password_second
                        })}
                        {...getFieldProps('password_second')}
                      />
                      {touched.password_second && errors.password_second ? (
                        <span className="form-error" aria-live="polite">
                          {errors.password_second}
                        </span>
                      ) : null}
                    </div>
                    {
                      company ? ( 
                        <div className="form-item">
                          <label className="form-label" htmlFor="company">{ dictionary.forms.fields.affiliatedCompany }*</label>
                          <input
                            type="text"
                            id="company"
                            disabled
                            className="form-input"
                            value={company}
                          />
                        </div>
                      ) : null
                    }
                    <div className="form-buttons single-centered">
                      <button type="submit" className="btn primary ghost" disabled={loading}>{ dictionary.forms.actions.register }</button>
                    </div>
                    <div className="form-item legal-mentions">
                      { dictionary.forms.phrases.legal }
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          ) : null
        }
        {
          showValidationSuccess ? (
            <Modal onClose={closeValidationSuccess} largeModal={true}>
              <div className="modal-icon modal-success">
                <div className="icon" aria-label="✓"></div>
                <div className="text">{ dictionary.validationSuccess.welcome }</div>
              </div>
              <div className="modal-text">
                { dictionary.validationSuccess.hello } { user.name } { user.first_name },<br/>
                { dictionary.validationSuccess.text1 }<br/>
                <br/>
                { dictionary.validationSuccess.text2 }
              </div>
              <div className="modal-text text-center">
                <Link to="/" className="btn primary">{ dictionary.validationSuccess.link }</Link>
              </div>
            </Modal>
          ) : null
        }
        {
          showTokenExpired ? (
            <Modal onClose={() => setShowTokenExpired(false)} largeModal={true}>
              <div className="modal-icon modal-error">
                <div className="icon" aria-label="!"></div>
                <div className="text">{ dictionary.tokenExpired.oops }</div>
              </div>
              <div className="modal-text">
                { dictionary.tokenExpired.text1 }<br/>
                { dictionary.tokenExpired.text2 }
              </div>
              <div className="modal-text text-center">
                <button type="button" className="btn primary" onClick={() => setShowContactForm(true)}>Contact</button>
              </div>
            </Modal>
          ) : null
        }
        {
          showContactForm && <ContactForm onClose={() => setShowContactForm(false)} onSuccess={() => setShowContactFormSuccess(true) } />
        }
        {
          showContactFormSuccess && (
            <Modal onClose={handleCloseContactFormSuccess}>
              <div className="modal-icon modal-success">
                <div className="icon" aria-label="✓"></div>
              </div>
              <div className="modal-text">
                { dictionary.contact.success }
              </div>
            </Modal>
          )
        }
      </div>
    </>
  )
}

export default Validation
