// runway shows hub page
import React, { useCallback, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

import CategoryNav from '../../../Components/CategoryNav'
import CustomSelect from '../../../Components/CustomSelect'
import DownloadButton from '../../../Components/DownloadButton'
import ExpiredCode from '../../../Components/ExpiredCode'
import Footer from '../../../Components/Footer'
import Header from '../../../Components/Header'
import LazyImage from '../../../Components/LazyImage'
import Nav from '../../../Components/Nav'
import RunwayShowThemeItem from '../../../Components/RunwayShowThemeItem'

import { LanguageContext } from '../../../Contexts/Language'
import { getRunwayShowsBySection, getRunwayShowCategories, getRunwayShowSections, getRunwayShowHubs, useAuthState, useAuthDispatch } from '../../../Contexts/MainContext'

import 'slick-carousel/slick/slick.css'

function RunwayShows(props) {

  const { userLanguage, dictionary } = useContext(LanguageContext)

  const dispatch = useAuthDispatch()
	const { token, seasons } = useAuthState()

	const [displayHubs, setDisplayHubs] = useState(false)
	const [displayShows, setDisplayShows] = useState(false)

	const [currentCityID, setCurrentCityID] = useState('all')

	const [categories, setCategories] = useState([])
	const [cities, setCities] = useState([])
	const [hubs, setHubs] = useState([])
	const [sections, setSections] = useState([])
	const [shows, setShows] = useState()

	const sliderSettings = {
		responsive: [
			{
				breakpoint: 10000,
				settings: 'unslick'
			},
			{
				breakpoint: 767,
				settings: {
					centerMode: true,
					centerPadding: '32px',
					dots: false,
					arrows: false,
					infinite: false,
				}
			}
		]
	}

	const getUniqueCities = useCallback((data) => {
		let allCities = [
			{
				id: "all",
				name: dictionary.runwayShows.cities
			}
		]
		for (const key in Object.entries(data.thematics)) {
			for (const key1 in Object.entries(data.thematics[key].gallery)) {
				allCities.push(data.thematics[key].gallery[key1].city)
			}
		}
		return allCities.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id && t.name===v.name))===i)
	}, [dictionary])

	const handleGetCategories = useCallback(async (seasonID) => {
		try {
			const payload = {
				"season": seasonID
			}
			let response = await getRunwayShowCategories(dispatch, token, userLanguage, payload)
			if (!response) return
			if (response.code === 'OK') {
				if (response.data.categories.length > 0) {
					setCategories(response.data.categories)
				} else {
					setCategories([])
				}
			}
		} catch (error) {
			console.log(error)
		}
	}, [dispatch, token, userLanguage])

	const handleGetHubs = useCallback(async (seasonID) => {
		try {
			const payload = {
				"season": seasonID
			}
			let response = await getRunwayShowHubs(dispatch, token, userLanguage, payload)
			if (!response) return
			if (response.code === 'OK') {
				if (response.data) {
					if (response.data.length) {
						setHubs(response.data)
					} else {
						setHubs([])
					}
				}
			}
		} catch (error) {
			console.log(error)
		}
	}, [dispatch, token, userLanguage])

	const handleGetSectionList = useCallback(async (categoryID, seasonID) => {
		let payload = {
			"category": categoryID,
			"season": seasonID
		}
		try {
			let response = await getRunwayShowSections(dispatch, token, userLanguage, payload)
			if (!response) return
			if (response.code === 'OK') {
				if (response.data.length > 0) {
					setSections(response.data)
				} else {
					setSections([])
				}
			}
		} catch (error) {
			console.log(error)
		}
	}, [dispatch, token, userLanguage])

	const handleGetShows = useCallback(async (sectionID) => {
		let payload = {
			"rubrique": sectionID
		}
		try {
			let response = await getRunwayShowsBySection(dispatch, token, userLanguage, payload)
			if (!response) return
			if (response.code === 'OK') {
				if (response.data) {
					setShows(response.data)
					setCurrentCityID('all')
					setCities(getUniqueCities(response.data))
				} else {
					setShows()
					setCurrentCityID('all')
					setCities([])
				}
			}
		} catch (error) {
			console.log(error)
		}
	}, [dispatch, token, userLanguage, getUniqueCities])

	const handleSeasonChange = (id) => {
		props.history.push('/runway-shows/' + id)
	}   

	const handleCityChange = (cityID) => {
		if (cityID === 'all') {
			setCurrentCityID('all')
		} else {
			setCurrentCityID(cityID)
		}
	}

	useEffect(() => {
		if (props.match.params.seasonID) {
			handleGetCategories(props.match.params.seasonID)
			if (!props.match.params.categoryID) {
				handleGetHubs(props.match.params.seasonID)
			} else if (props.match.params.sectionID) {
				handleGetSectionList(props.match.params.categoryID, props.match.params.seasonID)
				handleGetShows(props.match.params.sectionID)
			}
		}
	}, [props.match.params.seasonID, props.match.params.categoryID, props.match.params.sectionID, handleGetCategories, handleGetHubs, handleGetSectionList, handleGetShows])

	useEffect(() => {
		if (hubs.length && categories.length) {
			setDisplayShows(false)
			setDisplayHubs(true)
		}
	}, [hubs, categories])

	useEffect(() => {
		if (shows && sections.length && categories.length) {
			setDisplayHubs(false)
			setDisplayShows(true)
		}
	}, [shows, sections, categories])

	return (
		<>
			<Helmet>
				<html lang={userLanguage} />
			</Helmet>
			<div className="page runway-shows">
				<ExpiredCode />
				<Nav {...props} />
				<div className="main-content">
					<Header title={ dictionary.nav.main.runwayShows } text={ dictionary.runwayShows.intro } />
					{
						seasons ? (
							<>
								<div className="runway-shows--season-nav">
									<div className="runway-shows--season-nav--label">Archives</div>
									{
										props.match.params.seasonID ? (
											<CustomSelect options={seasons} onChange={handleSeasonChange} currentOption={parseInt(props.match.params.seasonID)} />
										) : null
									}
								</div>
								<div className="category-nav--wrapper">
									<CategoryNav>
										<Link
											to={"/runway-shows/" + props.match.params.seasonID }
											className={classNames('btn-unstyled nav-link', {
												'active': !props.match.params.categoryID }
											)}
										>
											{ dictionary.general.all }
										</Link>
										{
											categories.length > 0 ? (
												categories.map(function(category) {
													return (
														<Link
															key={category.id}
															to={'/runway-shows/' + props.match.params.seasonID + '/' + category.id+ '/' + category.rubrique.id}
															className={classNames('btn-unstyled nav-link', {
																'active': category.id === parseInt(props.match.params.categoryID) }
															)}
														>
															{ category.name }
														</Link>
													)
												})
											) : null
										}
									</CategoryNav>
									{
										displayShows ? (
											cities.length > 0 ? (
												<div className="runway-shows--city-selector">
													<CustomSelect options={cities} onChange={handleCityChange} currentOption={currentCityID} />
												</div>
											) : null
										) : null
									}
								</div>
								{
									displayHubs ? (
										hubs.map(function(category) {
											return (
												<div key={category.id} className="category-block runway-show--theme--block">
													<h2 className="lvl2-title category-block--title runway-show--theme--block--title">
														{ category.name }
													</h2>
													{
														category.thematics.length > 1 ? (
															<Slider className="category-block--slider" {...sliderSettings}>
																{
																	Object.keys(category.thematics).map((key, i) => (
																		<RunwayShowThemeItem key={i} item={category.thematics[key]} seasonID={props.match.params.seasonID} categoryID={category.id} section={category.thematics[key].rubrique} />
																	))
																}
															</Slider>
														) : (
															<div className="category-block--slider">
																{
																	Object.keys(category.thematics).map((key, i) => (
																		<RunwayShowThemeItem key={i} item={category.thematics[key]} seasonID={props.match.params.seasonID} categoryID={category.id} section={category.thematics[key].rubrique} />
																	))
																}
															</div>
														)
													}
													<a href={'/runway-shows/' + props.match.params.seasonID + '/' + category.id + '/' + category.thematics[0].rubrique.id} className="arrow-btn arrow-fwd primary category-block--link runway-show--theme--block--link">
														{ dictionary.runwayShows.all } {category.name}
													</a>
												</div>
											)
										})
									) : null
								}
								{
									displayShows ? (
										<div className="runway-show--section-nav">
											<div className="runway-show--section-nav--scroll">
												{
													sections.map(function(section) {
														return (
															<Link
																key={section.id}
																to={"/runway-shows/" + props.match.params.seasonID + '/' + props.match.params.categoryID + '/' + section.id}
																className={classNames('btn-unstyled runway-show--section-nav--item', {
																	'active': section.id === props.match.params.sectionID }
																)}
															>
																{ section.name }
															</Link>
														)
													})
												}
											</div>
										</div>
									) : null
								}
								{
									displayShows ? (
										shows.thematics.map(function(section) {
											if (section.gallery.length > 0) {
												let found = null
												if (currentCityID !== 'all') {
													found = Object.keys(section.gallery).filter(function(key) {
														return section.gallery[key].city.id === currentCityID
													})
												}
												if (currentCityID === 'all' || found.length > 0) {
													return (
														<div key={section.id} className="category-block runway-show--block">
															<h2 className="lvl2-title category-block--title runway-show--block--title">
																{ section.thematic }
															</h2>
															<Slider className="category-block--slider" {...sliderSettings}>
															{
																	section.gallery.map(function(gallery) {
																		if (currentCityID === 'all' || (currentCityID !== 'all' && currentCityID === gallery.city.id)) {
																			return (
																				<div key={gallery.id} className="runway-show--thumbnail">
																					<div className="runway-show--thumbnail--image">
																						<div className="runway-show--thumbnail--city">{ gallery.city.name }</div>
																						<div className="runway-show--thumbnail--image--right">
																							{
																								gallery.is_new ? (
																									<div className="runway-show--thumbnail--is-new">{ dictionary.general.new }</div>
																								) : null
																							}
																							<DownloadButton
																								cssClasses={"btn-unstyled runway-show--thumbnail--download"}
																								url={gallery.image}
																								filename={gallery.image.split('\\').pop().split('/').pop()}
																							/>
																						</div>
																						<LazyImage
																							src={gallery.image_thumbnail}
																							alt={gallery.title}
																							aspectRatio={262/391}
																						/>
																					</div>
																					<div className="runway-show--thumbnail--credit">{ gallery.credit }</div>
																					<div className="runway-show--thumbnail--info">{ gallery.information }</div>
																				</div>
																			)
																		}
																		return false
																	})
																}
															</Slider>
														</div>
													)
												}
											}
											return false
										})
									) : null
								}
							</>
						) : null
					}
					<Footer />
				</div>
			</div>
		</>
	)
}

export default RunwayShows
