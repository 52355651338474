import React, { useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import useOutsideClick from '../Utilities/useOutsideClick'

const CustomSelect = ({ onChange, options, currentOption }) => {

  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState()

  const toggling = () => setIsOpen(!isOpen)

  const onOptionClicked = value => () => {
    setSelectedOption(value)
    setIsOpen(false)
    onChange(value.id)
  }

	const clickOutside = useRef()

	useOutsideClick(clickOutside, () => {
		setIsOpen(false)
  })

  useEffect(() => {
    setSelectedOption(options.find(x => x.id === currentOption))
  }, [options, currentOption])

  return (
    <div className="custom-select" ref={clickOutside}>
      <button type="button" className={classNames('btn-unstyled custom-select--trigger', {'active': isOpen})} onClick={toggling}>
        { selectedOption && selectedOption.name }
      </button>
      {isOpen && (
        <div className="custom-select--dropdown">
          <div className="custom-select--dropdown-list">
            {options.map((option, key) => (
              <button
                key={key}
                type="button"
                className={classNames('btn-unstyled custom-select--dropdown--item', {'active': selectedOption === option})}
                onClick={onOptionClicked(option)}
              >
                {option.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomSelect
