// profile page
import React, { useContext, useState } from 'react'

import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'
import { useFormik } from 'formik'

import readableDate from '../../../Utilities/readableDate'

import CategoryNav from '../../../Components/CategoryNav'
import ContactForm from '../../../Components/ContactForm'
import Footer from '../../../Components/Footer'
import Header from '../../../Components/Header'
import Modal from '../../../Components/Modal'
import Nav from '../../../Components/Nav'

import ProfileContent from './ProfileContent'
import Subscribers from './Subscribers'

import { LanguageContext } from '../../../Contexts/Language'
import { activationCode, useAuthState, useAuthDispatch } from '../../../Contexts/MainContext'

function Home(props) {

  const { userLanguage, dictionary } = useContext(LanguageContext)

	const dispatch = useAuthDispatch()
	const { user, loading, token } = useAuthState()

  const [expiryDate, setExpiryDate] = useState(user.company.end_subscription)

	const [showCodeOK, setShowCodeOK] = useState(false)
  const [showContactForm, setShowContactForm] = useState(false)
	const [showContactFormSuccess, setShowContactFormSuccess] = useState(false)
	const [showProfile, setShowProfile] = useState(true)
	const [showRenewCode, setShowRenewCode] = useState(false)
	const [showSubscribers, setShowSubscribers] = useState(false)

  const openSubscribers = () => {
    setShowSubscribers(true)
    setShowProfile(false)
  }

  const openProfile = () => {
    setShowProfile(true)
    setShowSubscribers(false)
  }

  const handleCloseContactFormSuccess = () => {
		setShowContactFormSuccess(false)
		setShowContactForm(false)
	}

  const newCodeForm = useFormik({
    initialValues: {
      activation_code: ''
    },
    validate: values => {
      const errors = {}
      if (!values.activation_code) {
        errors.activation_code = dictionary.forms.errors.noCode
      }

      return errors
    },
    onSubmit: async (values, actions) => {
      try {
        let sendNewCodeResponse = await activationCode(dispatch, values, token)
        if (!sendNewCodeResponse) return

        if (sendNewCodeResponse.email_address) {
          setExpiryDate(sendNewCodeResponse.data.company.end_subscription)
          setShowCodeOK(!showCodeOK)
        } else if (sendNewCodeResponse.code === 'MISSING_ACTIVATION_CODE') {
          actions.setFieldError('activation_code', dictionary.renewCodePopin.missingCodeError)
        } else if (sendNewCodeResponse.code === 'INVALID_ACTIVATION_CODE') {
          actions.setFieldError('activation_code', dictionary.renewCodePopin.invalidCodeError)
        }
      } catch (error) {
        console.log(error)
      }
    },
  })

  let renewCodeTxt = ''
  if (user.ambassador_account) {
    renewCodeTxt = dictionary.profile.renewAmbassador
  } else {
    renewCodeTxt = dictionary.profile.renewUser
  }

	return (
		<>
			<Helmet>
				<html lang={userLanguage} />
			</Helmet>
			<div className="page profile">
				<Nav {...props} />
				<div className="main-content">
          <Header title={ user.first_name + ' ' + user.name }>
            <p className="page-header--text">
              { dictionary.profile.expiresOn } { readableDate(expiryDate, userLanguage) }<br/>
              { renewCodeTxt }
            </p>
            {
              user.ambassador_account ? (
                // ambassador account
                <div className="btn-container horizontal">
                  <button type="button" className="btn primary" onClick={() => setShowContactForm(true)}>Contact</button>
                </div>
              ) : (
                // standard account
                user.company.type === 'standard' ? (
                  // standard company
                  <div className="btn-container horizontal">
                    <a href={"mailto:" + user.ambassador.email_address} className="btn primary">Contact</a>
                  </div>
                ) : (
                  // university
                  user.company.activation_code_status === 'expired' ? (
                    <div className="btn-container horizontal">
                      <button type="button" className="btn primary" onClick={() => setShowRenewCode(true)}>{ dictionary.expiredCodeBanner.button }</button>
                      <a href={"mailto:" + user.ambassador.email_address} className="btn primary ghost">Contact</a>
                    </div>
                  ) : (
                    <div className="btn-container horizontal">
                      <a href={"mailto:" + user.ambassador.email_address} className="btn primary">Contact</a>
                    </div>
                  )
                )
              )
            }
          </Header>
          {
            user.ambassador_account ? (
              <>
                <div className="category-nav--wrapper">
                  <CategoryNav>
                    <button type="button" className={classNames('btn-unstyled nav-link', { 'active': showSubscribers })} onClick={openSubscribers}>{ dictionary.profile.nav.subscribers }</button>
                    <button type="button" className={classNames('btn-unstyled nav-link', { 'active': showProfile })} onClick={openProfile}>{ dictionary.profile.nav.profile }</button>
                  </CategoryNav>
                </div>
                {
                  showProfile ? (
                    <ProfileContent />
                  ) : null
                }
                {
                  showSubscribers ? (
                    <Subscribers />
                  ) : null
                }
              </>
            ) : (
              <>
                <div className="category-nav--wrapper">
                  <CategoryNav>
                    <div className="btn-unstyled nav-link active">{ dictionary.profile.nav.profile }</div>
                  </CategoryNav>
                </div>
                {
                  showProfile ? (
                    <ProfileContent />
                  ) : null
                }
              </>
            )
          }
          <Footer />
				</div>
			</div>
			{
				user.company.activation_code_status === 'expired' && showRenewCode ? (
					<Modal onClose={() => setShowRenewCode(false)}>
						<div className="modal-title opacity">
							{ dictionary.renewCodePopin.title }
						</div>
						<form noValidate onSubmit={newCodeForm.handleSubmit}>
							<div className="form-item">
								<div className="activation-code">
									<input
										type="password"
										id="activation_code"
										placeholder={dictionary.forms.fields.typeNewCode}
										disabled={loading}
										className={classNames('form-input', {
											'is-invalid': newCodeForm.touched.activation_code && newCodeForm.errors.activation_code
										})}
										{...newCodeForm.getFieldProps('activation_code')}
									/>
									<button type="submit" className="btn-unstyled activate-code" disabled={loading} aria-label={dictionary.forms.actions.validate}></button>
								</div>
								{newCodeForm.touched.activation_code && newCodeForm.errors.activation_code ? (
									<span className="form-error" aria-live="polite">
										{newCodeForm.errors.activation_code}
									</span>
								) : null}
							</div>
						</form>
					</Modal>
				) : null
			}
			{
				showCodeOK ? (
					<Modal onClose={() => setShowCodeOK(false)}>
						<div className="modal-icon modal-success">
              <div className="icon" aria-label="✓"></div>
            </div>
						<div className="modal-title opacity">
							{ dictionary.renewCodePopin.codeOK } { readableDate(expiryDate, userLanguage) }
						</div>
					</Modal>
				) : null
			}
      {
        showContactForm && <ContactForm onClose={() => setShowContactForm(false)} onSuccess={() => setShowContactFormSuccess(true) } />
      }
      {
        showContactFormSuccess && (
          <Modal onClose={handleCloseContactFormSuccess}>
            <div className="modal-icon modal-success">
              <div className="icon" aria-label="✓"></div>
            </div>
            <div className="modal-text">
              { dictionary.contact.success }
            </div>
          </Modal>
        )
      }
		</>
	)
}

export default Home
