import React, { useContext, useState } from 'react'

import classNames from 'classnames'
import ReactPlayer from 'react-player'

import LazyImage from '../../../../../Components/LazyImage'

import { LanguageContext } from '../../../../../Contexts/Language'

const AdditionalContentExpand = ({ expandData, show, onClose }) => {

  const { dictionary } = useContext(LanguageContext)

  return (
    <div className={classNames('pdf-viewer--additional-content--overlay', {'show': show })}>
      <div className={classNames('pdf-viewer--additional-content--overlay--inner', {'video': expandData.type === 'video'})}>
        {
          expandData.type === 'video' ? (
            <div className="pdf-viewer--additional-content--video">
              {
                show ? (
                  <ReactPlayer
                    className="pdf-viewer--additional-content--video-player"
                    controls={true}
                    height="100%"
                    playing={true}
                    url={expandData.url}
                    width="100%"
                  />
                ) : null
              }
            </div>
          ) : (
            <article
              className={classNames('pdf-viewer--additional-content--article', {
                'pdf-viewer--additional-content--archestyle': expandData.type === 'archestyle'
              })}
            >
              <h1 className="pdf-viewer--additional-content--article--title">{ expandData.title }</h1>
              <div className="pdf-viewer--additional-content--image">
                <LazyImage src={ expandData.image } aspectRatio={600/340} alt={ expandData.title } />
                {
                  expandData.type === 'archestyle' ? (
                    <div className="pdf-viewer--additional-content--graphic">
                      <LazyImage src={ expandData.graphic } aspectRatio={1/1} alt={ expandData.title } />
                    </div>
                  ) : null
                }
              </div>
              <div className="pdf-viewer--additional-content--article--desc" dangerouslySetInnerHTML={{__html: expandData.desc}}></div>
            </article>
          )
        }
        <button
          type="button"
          className="btn-unstyled pdf-viewer--btn-close"
          onClick={onClose}
          aria-label={dictionary.buttons.close}
        ></button>
      </div>
    </div>
  )
}

const AdditionalContent = ({ data, onClose }) => {

  const { dictionary } = useContext(LanguageContext)

  const [expandData, setExpandData] = useState([])
  const [showExpand, setShowExpand] = useState(false)

  const handleClick = (objData) => {
    setExpandData(objData)
    setShowExpand(true)
  }

  const handleCloseExpand = () => {
    setShowExpand(false)
  }

  const handleDownloadPanelClose = () => {
    onClose()
  }

	return (
    <>
      <div
        className="pdf-viewer--additional-content--close-overlay"
        onClick={handleDownloadPanelClose}
      ></div>
      <div className="pdf-viewer--additional-content--panel">
        <button
          type="button"
          className="btn-unstyled pdf-viewer--btn-close pdf-viewer--additional-content--btn-close"
          onClick={handleDownloadPanelClose}
          aria-label={dictionary.buttons.close}
        ></button>
        <div className="pdf-viewer--additional-content--list">
          <div className="pdf-viewer--additional-content--list--title">
            { dictionary.trendBooks.additional }
          </div>
          {
            Object.keys(data).map((key, i) => (
              <button
                key={i}
                type="button"
                className="btn-unstyled pdf-viewer--additional-content--item"
                onClick={() => handleClick(data[key])}
              >
                <div className="pdf-viewer--additional-content--image">
                  <LazyImage src={ data[key].thumbnail } aspectRatio={183/104} alt={ data[key].title } />
                  {
                    data[key].type === 'archestyle' ? (
                      <div className="pdf-viewer--additional-content--graphic">
                        <LazyImage src={ data[key].graphic } aspectRatio={1/1} alt={ data[key].title } />
                      </div>
                    ) : null
                  }
                </div>
                <div className="pdf-viewer--additional-content--item--title">{data[key].title}</div>
              </button>
            ))
          }
        </div>
      </div>
      <AdditionalContentExpand expandData={expandData} show={showExpand} onClose={handleCloseExpand} />
    </>
	)
}

export default AdditionalContent
