// site navigation
import React, { useContext, useState } from 'react'

import { Link, NavLink } from 'react-router-dom'

import { useAuthDispatch, useAuthState, logout } from '../Contexts/MainContext'
import { LanguageContext } from '../Contexts/Language'

import LanguageSelector from './LanguageSelector'
import Logo from './Logo'

function Nav() {

  const dispatch = useAuthDispatch()
  const { seasons } = useAuthState()

  const { dictionary } = useContext(LanguageContext)

  const [showMenu, setShowMenu] = useState(false)

  const toggleClassName = showMenu ? 'nav show' : 'nav'

	const handleLogout = () => {
		logout(dispatch)
  }

  const handleMenuBtn = () => {
    setShowMenu(!showMenu)
  }

	return (
    <header className={toggleClassName}>
      <div className="navbar">
        <Link to="/" className="nav--logo">
          <Logo />
        </Link>
        <button type="button" className="btn-unstyled btn-menu" onClick={handleMenuBtn} aria-label={ dictionary.nav.aria.openMenu }></button>
      </div>
      <div className="nav-menu">
        <button type="button" className="btn-unstyled btn-menu btn-close" onClick={handleMenuBtn} aria-label={ dictionary.nav.aria.closeMenu }></button>
        <nav className="nav--main-menu">
          <div className="nav-item">
            <NavLink exact to="/" className="nav-link">{ dictionary.nav.main.myPeclersPlus }</NavLink>
          </div>
          <div className="nav-item">
            <NavLink
              className="nav-link"
              to="/trend-books/all"
              isActive={(match, location) => location.pathname.indexOf('/trend-book') > -1}
            >
              { dictionary.nav.main.trendBooks }
            </NavLink>
          </div>
          {
            seasons ? (
              <div className="nav-item">
                <NavLink
                  className="nav-link"
                  to={"/runway-shows/" + seasons[0].id }
                  isActive={(match, location) => location.pathname.indexOf('/runway-shows/') > -1}
                >
                  { dictionary.nav.main.runwayShows }
                </NavLink>
              </div>
            ) : null
          }
          <div className="nav-item">
            <NavLink
              className="nav-link"
              to="/trend-reports/all"
              isActive={(match, location) => location.pathname.indexOf('/trend-report') > -1}
            >
              { dictionary.nav.main.trendReports }
            </NavLink>
          </div>
        </nav>
        <nav className="nav--side-menu">
          <div className="nav-item">
            <NavLink to="/profile" className="nav-link nav-profile"><span>{ dictionary.nav.side.profile }</span></NavLink>
          </div>
          <div className="nav-item">
            <NavLink to="/help" className="nav-link nav-help"><span>{ dictionary.nav.side.help }</span></NavLink>
          </div>
          <div className="nav-item">
            <button type="button" className="btn-unstyled nav-link nav-logout" onClick={handleLogout}>
              <span>{ dictionary.nav.side.logOut }</span>
            </button>
          </div>
          <div className="nav-item lang-switcher">
            <LanguageSelector />
          </div>
        </nav>
      </div>
    </header>
	)
}

export default Nav
