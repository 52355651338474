// expired code banner (showed during the last month of a code's lifespan, once a week)
import React, { useContext, useState, useEffect } from 'react'

import classNames from 'classnames'
import { useFormik } from 'formik'

import Modal from '../Components/Modal'

import { activationCode, useAuthState, useAuthDispatch } from '../Contexts/MainContext'
import { LanguageContext } from '../Contexts/Language'

import readableDate from '../Utilities/readableDate'

function ExpiredCode() {

	const { userLanguage, dictionary } = useContext(LanguageContext)

	const dispatch = useAuthDispatch()
	const { user, loading, token } = useAuthState()

	const [showRenewCodeForm, setShowRenewCodeForm] = useState(false)
	const [showBanner, setShowBanner] = useState(false)
	const [showCodeOK, setShowCodeOK] = useState(false)
	const [expiryDate, setExpiryDate] = useState('')

	const setCookie = (cname, cvalue, exdays) => {
    const d = new Date()
    d.setTime(d.getTime() + (exdays*24*60*60*1000))
    const expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + '; ' + expires
	}

	const getCookie = cname => {
    const name = cname + '='
    const ca = document.cookie.split(';')
    for (var i=0; i < ca.length; i++) {
			let c = ca[i]
			while (c.charAt(0) === ' ') c = c.substring(1)
			if (c.indexOf(name) !== -1) return c.substring(name.length, c.length)
    }
    return ''
	}

	useEffect(() => {
		if (user) {
			if (!user.ambassador_account && user.company.activation_code_status === 'expired') {
				const banner_seen = getCookie('banner')
				if (banner_seen === '') {
					setShowBanner(true)
					setCookie('banner', 'seen', 7)
				}
			}
		}
	}, [user])

	const closeShowCodeOK = () => {
		setShowCodeOK(false)
		setShowBanner(false)
	}

	const newCodeForm = useFormik({
		initialValues: {
			activation_code: ''
		},
		validate: values => {
			const errors = {}
			if (!values.activation_code) {
				errors.activation_code = dictionary.forms.errors.noCode
			}

			return errors
		},
		onSubmit: async (values, actions) => {
			try {
				let sendNewCodeResponse = await activationCode(dispatch, values, token)
				if (!sendNewCodeResponse) return

				if (sendNewCodeResponse.email_address) {
					const date = readableDate(sendNewCodeResponse.company.activation_code_limit_date, userLanguage)
          setExpiryDate(date)
					setShowRenewCodeForm(false)
					setShowCodeOK(!showCodeOK)
				} else if (sendNewCodeResponse.code === 'MISSING_ACTIVATION_CODE') {
					actions.setFieldError('activation_code', dictionary.renewCodePopin.missingCodeError)
				} else if (sendNewCodeResponse.code === 'INVALID_ACTIVATION_CODE') {
					actions.setFieldError('activation_code', dictionary.renewCodePopin.invalidCodeError)
				}
			} catch (error) {
				console.log(error)
			}
		},
	})

	return (
		<>
			{
				showBanner ? (
					<div className="expired-code">
						<div className="expired-code--text">
							<b>{ dictionary.expiredCodeBanner.content1 } { readableDate(user.company.activation_code_limit_date, userLanguage) }</b>, { dictionary.expiredCodeBanner.content2 }
						</div>
						<div className="expired-code--buttons">
							<button type="button" className="btn primary" onClick={() => setShowRenewCodeForm(true)}>{ dictionary.expiredCodeBanner.button }</button>
							<a href={"mailto:" + user.ambassador.email_address} className="btn primary ghost">Contact</a>
						</div>
						<button type="button" className="btn-unstyled close-btn" aria-label={ dictionary.buttons.close } onClick={() => setShowBanner(false)}></button>
					</div>
				) : null
			}
			{
				showRenewCodeForm ? (
					<Modal onClose={() => setShowRenewCodeForm(false)}>
						<div className="modal-title opacity">
							{ dictionary.renewCodePopin.title }
						</div>
						<form noValidate onSubmit={newCodeForm.handleSubmit}>
							<div className="form-item">
								<div className="activation-code">
									<input
										type="text"
										id="activation_code"
										placeholder={dictionary.forms.fields.typeNewCode}
										disabled={loading}
										className={classNames('form-input', {
											'is-invalid': newCodeForm.touched.activation_code && newCodeForm.errors.activation_code
										})}
										{...newCodeForm.getFieldProps('activation_code')}
									/>
									<button type="submit" className="btn-unstyled activate-code" disabled={loading} aria-label={dictionary.forms.actions.validate}></button>
								</div>
								{newCodeForm.touched.activation_code && newCodeForm.errors.activation_code ? (
									<span className="form-error" aria-live="polite">
										{newCodeForm.errors.activation_code}
									</span>
								) : null}
							</div>
						</form>
					</Modal>
				) : null
			}
			{
				showCodeOK ? (
					<Modal onClose={closeShowCodeOK}>
						<div className="modal-icon modal-success">
							<div className="icon" aria-label="✓"></div>
						</div>
						<div className="modal-text">
							{ dictionary.renewCodePopin.codeOK } { expiryDate }
						</div>
					</Modal>
				) : null
			}
		</>
	)
}

export default ExpiredCode
