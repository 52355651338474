import React from 'react'

import TrendReportThumbnail from './TrendReportThumbnail'

const TrendReportGrid = ({ title, items, useLarge }) => {

  return (
    <div className="trend-report--grid">
      {
        title ? (
          <h2 className="lvl3-title trend-report--grid--title">{ title }</h2>
        ) : null
      }
      <div className="trend-report--grid--items">
        {
          items ? (
            Object.keys(items).map((key, i) => {
              if (useLarge) {
                if (i % 4) {
                  return (
                    <TrendReportThumbnail key={i} item={items[key]} large={false} />
                  )
                } else {
                  return (
                    <TrendReportThumbnail key={i} item={items[key]} large={true} />
                  )
                }
              } else {
                return (
                  <TrendReportThumbnail key={i} item={items[key]} large={false} />
                )
              }
            })
          ) : null
        }
      </div>
    </div>
  )
}

export default TrendReportGrid
