// site footer
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { LanguageContext } from '../Contexts/Language'

function Footer() {
  
  const { dictionary } = useContext(LanguageContext)

	return (
    <footer className="footer">
      <nav className="nav--footer">
        <div className="nav-item">
          <span className="nav-link">© Peclers Paris</span>
        </div>
        <div className="nav-item">
          <NavLink to="/about" className="nav-link">{ dictionary.footer.about }</NavLink>
        </div>
        <div className="nav-item">
          <NavLink to="/agents" className="nav-link">{ dictionary.footer.agents }</NavLink>
        </div>
        <div className="nav-item">
          <NavLink to="/terms" className="nav-link">{ dictionary.footer.terms }</NavLink>
        </div>
        <br className="visible-md"/>
        <div className="nav-item">
          <NavLink to="/disclaimer" className="nav-link">{ dictionary.footer.disclaimer }</NavLink>
        </div>
        <div className="nav-item">
          <NavLink to="/privacy" className="nav-link">{ dictionary.footer.privacy }</NavLink>
        </div>
      </nav>
    </footer>
	)
}

export default Footer
