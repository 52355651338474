// pop-in shown after a login attempt if the user's activation code is out of date
import React, { useContext } from 'react'

import { LanguageContext } from '../Contexts/Language'

import desktopImage from '../assets/images/backgrounds/renew-code--desktop.jpg'
import desktopRetinaImage from '../assets/images/backgrounds/renew-code--desktop@2x.jpg'
import mobileImage from '../assets/images/backgrounds/renew-code--mobile@2x.jpg'
import mobileRetinaImage from '../assets/images/backgrounds/renew-code--mobile@4x.jpg'

const InactiveCode = ({ children, emailAddress }) => {

  const { dictionary } = useContext(LanguageContext)

  return (
    <div className="modal renew-code show">
      <div className="modal-dialog modal-animate-top">
        <div className="modal-panel">
          <picture className="renew-code--image">
            <source srcSet={ `${desktopImage} 1x, ${desktopRetinaImage } 2x`} media="(min-width: 768px)" />
            <source srcSet={ `${mobileImage} 1x, ${mobileRetinaImage } 2x`} media="(max-width: 767px)" />
            <img className="renew-code--image--img" src={desktopImage} alt="" />
          </picture>
          <div className="renew-code--content">
            <div className="renew-code--form">
              <h2 className="renew-code--title">
                { dictionary.forms.phrases.expiredCode }
              </h2>
              <p className="renew-code--help">
                { dictionary.forms.phrases.expiredCodeHelp }
              </p>
              {children}
            </div>
            <div className="renew-code--footer">
              <p>
                { dictionary.forms.phrases.expiredCodeProblem }
              </p>
              <a href={ "mailto:" + emailAddress} className="arrow-btn arrow-fwd primary small">Contact</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InactiveCode
