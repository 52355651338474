// makes links inside PDFs clickable (used on TrendBook Viewer)

import { AnnotationType } from '@react-pdf-viewer/core'

export const customAnnotationPlugin = () => {
  const onAnnotationLayerRender = (e) => {
    // Find all `Link` annotation
    e.annotations
      .filter(annotation => annotation.annotationType === AnnotationType.Link)
      .forEach(annotation => {
        if (annotation.url) {
          // Find the `a` element represents the link
          [...e.container.querySelectorAll('.rpv-core__annotation-link a')].forEach(linkEle => {
          linkEle.setAttribute('target', '_blank')
          })
        }
      })
  }

  return {
    onAnnotationLayerRender,
  }
}