const ROOT_URL = 'https://' + process.env.REACT_APP_PECLERS_BACK_URL + '/api'
const ENV = process.env.NODE_ENV

//// USER ACTIONS

// user authentification
export async function loginUser(dispatch, payload) {

	const browserLanguage = navigator.language === 'fr-FR' ? 'fr' : 'en'
	const preferredLanguage = window.localStorage.getItem('rcml-lang') || browserLanguage
	
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Accept-Language': preferredLanguage },
		body: JSON.stringify(payload),
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/user/login`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/user/login`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('response', {response})
			console.log('data',{data})
		}

		if (response.status === 400) {
			if (preferredLanguage === 'fr') {
				dispatch({ type: 'ERROR', error: 'Email ou mot de passe invalides.' })
			} else {
				dispatch({ type: 'ERROR', error: 'Incorrect email or password.' })
			}
		} else if (data.token) {
			dispatch({ type: 'LOGIN_SUCCESS', payload: data })
			localStorage.setItem('token', JSON.stringify(data.token))
			return data
		} else {
			dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
		}
	} catch (error) {
		if (error.message === 'Failed to fetch') {
			if (preferredLanguage === 'fr') {
				dispatch({ type: 'ERROR', error: 'Le serveur ne répond pas.' })
			} else {
				dispatch({ type: 'ERROR', error: 'The server is not responding.' })
			}
		} else {
			dispatch({ type: 'ERROR', error: error.message })
		}
	}
}

// user log out
export async function logout(dispatch) {
	dispatch({ type: 'LOGOUT' })
	localStorage.removeItem('user')
	localStorage.removeItem('token')
	localStorage.removeItem('seasons')
}

// get authenticated user info
export async function getUser(dispatch, token) {

	const browserLanguage = navigator.language === 'fr-FR' ? 'fr' : 'en'
	const preferredLanguage = window.localStorage.getItem('rcml-lang') || browserLanguage
	
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': preferredLanguage },
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/user/me`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/user/me`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('response', {response})
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.id) {
				dispatch({ type: 'GET_USER_SUCCESS', payload: data })
				localStorage.setItem('user', JSON.stringify(data))
				if (data.locale) {
					localStorage.setItem('rcml-lang', data.locale)
				}
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// update user profile info
export async function updateUser(dispatch, payload, token) {

	const browserLanguage = navigator.language === 'fr-FR' ? 'fr' : 'en'
	const preferredLanguage = window.localStorage.getItem('rcml-lang') || browserLanguage

	const requestOptions = {
		method: 'PUT',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': preferredLanguage },
		body: JSON.stringify(payload)
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/user/update`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/user/update`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('response', {response})
			console.log('data',{data})
		}

		if (response.status === 400) {
			dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			return data
		} else if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data) {
				dispatch({ type: 'PUT_SUCCESS', payload: data })
				localStorage.setItem('user', JSON.stringify(data))
				if (data.locale) {
					localStorage.setItem('rcml-lang', data.locale)
				}
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// create user using the subscription form (for university users)
export async function createUser(dispatch, payload) {

	const browserLanguage = navigator.language === 'fr-FR' ? 'fr' : 'en'
	const preferredLanguage = window.localStorage.getItem('rcml-lang') || browserLanguage
	
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Accept-Language': preferredLanguage },
		body: JSON.stringify(payload),
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/user/create`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/user/create`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : ( Array.isArray( data ) ? data[0].message : data.message ) ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// get user info in order to fill the validation form
export async function getRegistration(dispatch, pw_token) {

	const browserLanguage = navigator.language === 'fr-FR' ? 'fr' : 'en'
	const preferredLanguage = window.localStorage.getItem('rcml-lang') || browserLanguage
	
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'Accept-Language': preferredLanguage }
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/user/confirmation?pw_token=${pw_token}`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/user/confirmation?pw_token=${pw_token}`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.pw_token) {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// confirm user account
export async function validateUser(dispatch, payload) {

	const browserLanguage = navigator.language === 'fr-FR' ? 'fr' : 'en'
	const preferredLanguage = window.localStorage.getItem('rcml-lang') || browserLanguage
	
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Accept-Language': preferredLanguage },
		body: JSON.stringify(payload),
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/user/confirmation`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/user/confirmation`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (data.code === 'OK') {
			dispatch({ type: 'SUCCESS' })
		} else {
			dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
		}
		return data
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// ask for a password reset
export async function lostPassword(dispatch, payload) {

	const browserLanguage = navigator.language === 'fr-FR' ? 'fr' : 'en'
	const preferredLanguage = window.localStorage.getItem('rcml-lang') || browserLanguage
	
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Accept-Language': preferredLanguage },
		body: JSON.stringify(payload),
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/user/lostpassword`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/user/lostpassword`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (data.code === 'OK') {
			dispatch({ type: 'SUCCESS' })
		} else {
			dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
		}
		return data
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// change user password (from the profile)
export async function password(dispatch, payload, token) {

	const browserLanguage = navigator.language === 'fr-FR' ? 'fr' : 'en'
	const preferredLanguage = window.localStorage.getItem('rcml-lang') || browserLanguage

	const requestOptions = {
		method: 'PUT',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': preferredLanguage },
		body: JSON.stringify(payload),
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/user/password`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/user/password`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (data.code === 'OK') {
			dispatch({ type: 'SUCCESS' })
		} else {
			dispatch({ type: 'ERROR', error: data[0].message })
		}
		return data
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// change user password (from the lost password form, outside the app)
export async function changePassword(dispatch, payload) {

	const browserLanguage = navigator.language === 'fr-FR' ? 'fr' : 'en'
	const preferredLanguage = window.localStorage.getItem('rcml-lang') || browserLanguage
	
	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json', 'Accept-Language': preferredLanguage },
		body: JSON.stringify(payload),
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/user/lostpassword`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/user/lostpassword`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// update user activation code
export async function activationCode(dispatch, payload, token) {

	const browserLanguage = navigator.language === 'fr-FR' ? 'fr' : 'en'
	const preferredLanguage = window.localStorage.getItem('rcml-lang') || browserLanguage

	const requestOptions = {
		method: 'PUT',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': preferredLanguage },
		body: JSON.stringify(payload)
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/user/activation_code`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/user/activation_code`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('response', {response})
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.email_address) {
				dispatch({ type: 'PUT_SUCCESS', payload: data })
				localStorage.setItem('user', JSON.stringify(data))
				if (data.locale) {
					localStorage.setItem('rcml-lang', data.locale)
				}
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// get user list (for standard ambassadors)
export async function getMembers(dispatch, token, payload) {

	const browserLanguage = navigator.language === 'fr-FR' ? 'fr' : 'en'
	const preferredLanguage = window.localStorage.getItem('rcml-lang') || browserLanguage

	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': preferredLanguage }
	}
	const queryParams = new URLSearchParams(payload).toString()

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/company/member?${queryParams}`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/company/member?${queryParams}`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// get activation code list (for university ambassadors)
export async function getActivationCodes(dispatch, token) {

	const browserLanguage = navigator.language === 'fr-FR' ? 'fr' : 'en'
	const preferredLanguage = window.localStorage.getItem('rcml-lang') || browserLanguage
	
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': preferredLanguage },
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/company/activation_code`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/company/activation_code`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('response', {response})
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.data.activation_code) {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// create user (by a standard ambassador)
export async function addMember(dispatch, token, payload) {

	const browserLanguage = navigator.language === 'fr-FR' ? 'fr' : 'en'
	const preferredLanguage = window.localStorage.getItem('rcml-lang') || browserLanguage
	
	const requestOptions = {
		method: 'POST',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': preferredLanguage },
		body: JSON.stringify(payload),
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/company/member`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/company/member`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('response', {response})
			console.log('data',{data})
		}

		if (data.code === 'OK') {
			dispatch({ type: 'SUCCESS' })
		} else {
			console.log('data',{data})
			dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
		}
		return data
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// delete user (by a standard ambassador)
export async function deleteMember(dispatch, token, id) {

	const browserLanguage = navigator.language === 'fr-FR' ? 'fr' : 'en'
	const preferredLanguage = window.localStorage.getItem('rcml-lang') || browserLanguage
	
	const requestOptions = {
		method: 'DELETE',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': preferredLanguage }
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/company/member/${id}`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/company/member/${id}`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

//// GLOBAL

// content pages
export async function getContentPage(dispatch, token, language, content) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': language },
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/${content}/content`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/${content}/content`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('response', {response})
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.data) {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// contact form
export async function contactRequest(dispatch, payload) {

	const browserLanguage = navigator.language === 'fr-FR' ? 'fr' : 'en'
	const preferredLanguage = window.localStorage.getItem('rcml-lang') || browserLanguage
	
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Accept-Language': preferredLanguage },
		body: JSON.stringify(payload),
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/contact`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/contact`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (data.code === 'OK') {
			dispatch({ type: 'SUCCESS' })
		} else {
			dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
		}
		return data
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

//// CONTENT ACTIONS

// TREND BOOKS

// get the categories
export async function getTrendBookCategories(dispatch, token, language) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': language },
	}
	
	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/book/categories`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/book/categories`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// get trend books hub
export async function getTrendBookHub(dispatch, token, language) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': language },
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/book/hub`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/book/hub`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// get the trend books for a specific category
export async function getTrendBooksByCategory(dispatch, token, language, payload) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': language }
	}
	const queryParams = new URLSearchParams(payload).toString()

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/book/list?${queryParams}`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/book/list?${queryParams}`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// get current user's trend books
export async function getMyTrendBooks(dispatch, token, language, payload) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': language }
	}
	const queryParams = new URLSearchParams(payload).toString()

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/book/list/user?${queryParams}`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/book/list/user?${queryParams}`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// get a specific trend book (front page)
export async function getTrendBook(dispatch, token, language, id) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': language },
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/book/${id}`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/book/${id}`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('response', {response})
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// get a specific trend book (viewer)
export async function getTrendBookViewer(dispatch, token, language, id) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': language },
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/book/${id}/viewer`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/book/${id}/viewer`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// get the download link for a given asset list
export async function downloadAssets(dispatch, token, language, payload) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': language },
		body: JSON.stringify(payload)
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/book/download`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/book/download`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// RUNWAY SHOWS

// get the list of runway shows categories
export async function getRunwayShowCategories(dispatch, token, language, payload) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': language },
	}
	const queryParams = new URLSearchParams(payload).toString()

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/catwalk/categories?${queryParams}`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/catwalk/categories?${queryParams}`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// get the hub for a specific season
export async function getRunwayShowHubs(dispatch, token, language, payload) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': language },
	}
	const queryParams = new URLSearchParams(payload).toString()

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/catwalk/list_hub?${queryParams}`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/catwalk/list_hub?${queryParams}`, requestOptions)
		let data = await response.json()


		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// get the list of runway shows seasons
export async function getRunwayShowSeasons(dispatch, token, language) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': language },
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/catwalk/season`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/catwalk/season`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				const seasons = data.data.seasons.reverse()
				dispatch({ type: 'SEASONS_SUCCESS', payload: seasons })
				localStorage.setItem('seasons', JSON.stringify(seasons))
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// get the list of runway shows sections (= "rubrique")
export async function getRunwayShowSections(dispatch, token, language, payload) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': language },
	}
	const queryParams = new URLSearchParams(payload).toString()

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/catwalk/rubriques?${queryParams}`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/catwalk/rubriques?${queryParams}`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// get the list of runway shows for a specific section ("rubrique")
export async function getRunwayShowsBySection(dispatch, token, language, payload) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': language },
	}
	const queryParams = new URLSearchParams(payload).toString()

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/catwalk/rubrique?${queryParams}`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/catwalk/rubrique?${queryParams}`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// TREND REPORTS

// get the list of trend report categories
export async function getTrendReportCategories(dispatch, token, language) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': language },
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/trend/rubric`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/trend/rubric`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// get the trend report hub
export async function getTrendReportsHub(dispatch, token, language) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': language },
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/trend/list_hub`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/trend/list_hub`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// get the trend reports for a specific category
export async function getTrendReportsByCategory(dispatch, token, language, payload) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': language }
	}
	const queryParams = new URLSearchParams(payload).toString()

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/trend/list?${queryParams}`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/trend/list?${queryParams}`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}

// get a specific trend report
export async function getTrendReport(dispatch, token, language, id) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept-Language': language },
	}

	try {
		dispatch({ type: 'REQUEST' })

		if (ENV === 'development') {
			console.log('fetching', `${ROOT_URL}/trend/${id}`)
			console.log('requestOptions', {requestOptions})
		}

		let response = await fetch(`${ROOT_URL}/trend/${id}`, requestOptions)
		let data = await response.json()

		if (ENV === 'development') {
			console.log('data',{data})
		}

		if (response.status === 403) {
			logout(dispatch)
		} else {
			if (data.code === 'OK') {
				dispatch({ type: 'SUCCESS' })
			} else {
				dispatch({ type: 'ERROR', error: ( Array.isArray( data ) ? data[0].message : data.message ) })
			}
			return data
		}
	} catch (error) {
		dispatch({ type: 'ERROR', error: error.message })
	}
}
