// standard user registration
import React, { useContext, useState } from 'react'

import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'
import { useFormik } from 'formik'

import readableDate from '../../../Utilities/readableDate'

import InactiveCode from '../../../Components/InactiveCode'
import LanguageSelector from '../../../Components/LanguageSelector'
import Logo from '../../../Components/Logo'
import Modal from '../../../Components/Modal'

import { activationCode, createUser, getRunwayShowSeasons, getUser, loginUser, useAuthState, useAuthDispatch } from '../../../Contexts/MainContext'
import { LanguageContext } from '../../../Contexts/Language'

function Registration(props) {

  const { userLanguage, dictionary } = useContext(LanguageContext)

	const dispatch = useAuthDispatch()
  const { loading, errorMessage, token } = useAuthState()

	const [ambassadorEmail, setAmbassadorEmail] = useState('')
	const [showInactiveCode, setShowInactiveCode] = useState(false)
	
	const [expiryDate, setExpiryDate] = useState('')
	const [showCodeOK, setShowCodeOK] = useState(false)

  const [passwordValid, setPasswordValid] = useState(false)
	
  const createUserForm = useFormik({
    initialValues: {
      activation_code: '',
      first_name: '',
      name: '',
      email_address: '',
      job_title: '',
      locale: '',
      password_first: '',
      password_second: ''
    },
    validate: values => {
      const errors = {}

      if (!values.activation_code) {
        errors.activation_code = dictionary.forms.errors.noActivationCode
      }
      if (!values.first_name) {
        errors.first_name = dictionary.forms.errors.noFirstName
      }
      if (!values.name) {
        errors.name = dictionary.forms.errors.noLastName
      }
      if (!values.email_address) {
        errors.email_address = dictionary.forms.errors.noEmail
      } else if (!/\b[\w\.-]+@[\w\.-]+\.\w{2,}\b/gi.test(values.email_address)) {
        errors.email_address = dictionary.forms.errors.invalidEmail
      }
      if (!values.password_first) {
				errors.password_first = dictionary.forms.errors.noPasswordFirst
			} else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/.test(values.password_first)) {
				setPasswordValid(false)
				errors.password_first = dictionary.forms.errors.invalidPassword
			} else {
				setPasswordValid(true)
			}
			if (!values.password_second) {
				errors.password_second = dictionary.forms.errors.noPasswordSecond
			} else if (values.password_second !== values.password_first) {
				errors.password_second = dictionary.forms.errors.passwordMismatch
			}

      return errors
    },
    onSubmit: async (values) => {
      values.locale = userLanguage
      try {
        let createUserResponse = await createUser(dispatch, values)
        if (!createUserResponse.code) return

        if (createUserResponse.code === 'OK') {
          let loginResponse = await loginUser(dispatch, { email: values.email_address, password: values.password_first })
          if (!loginResponse.token) return

          let getUserResponse = await getUser(dispatch, loginResponse.token)
          if (!getUserResponse.id) return

          if (getUserResponse.company.activation_code_status === 'inactive') {
            setAmbassadorEmail(getUserResponse.ambassador.email_address)
            setShowInactiveCode(!showInactiveCode)
          } else {
            try {
              let getSeasonsResponse = await getRunwayShowSeasons(dispatch, loginResponse.token, userLanguage)
              if (getSeasonsResponse.code !== 'OK') return
            } catch (error) {
              console.log(error)
            }
            props.history.push('/')
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
  })

	const newCodeForm = useFormik({
		initialValues: {
			activation_code: ''
		},
		validate: values => {
			const errors = {}
			if (!values.activation_code) {
				errors.activation_code = dictionary.forms.errors.noCode
			}

			return errors
		},
		onSubmit: async (values, actions) => {
			try {
				let sendNewCodeResponse = await activationCode(dispatch, values, token)
				if (!sendNewCodeResponse) return

				if (sendNewCodeResponse.email_address) {
          const date = readableDate(sendNewCodeResponse.data.company.activation_code_limit_date, userLanguage)
          setExpiryDate(date)
					setShowCodeOK(!showCodeOK)
				} else if (sendNewCodeResponse.code === 'MISSING_ACTIVATION_CODE') {
					actions.setFieldError('activation_code', dictionary.renewCodePopin.missingCodeError)
				} else if (sendNewCodeResponse.code === 'INVALID_ACTIVATION_CODE') {
					actions.setFieldError('activation_code', dictionary.renewCodePopin.invalidCodeError)
				}
			} catch (error) {
				console.log(error)
			}
		},
	})

	function closeCodeOK() {
		setShowCodeOK(false)
		props.history.push('/')
	}

	return (
    <>
      <Helmet>
        <html lang={userLanguage} />
      </Helmet>
      <div className="landing-page">
        <LanguageSelector hidden={true} />
        <form noValidate className="register-form" onSubmit={createUserForm.handleSubmit}>
          {errorMessage ? <div className="panel-addon error">{errorMessage}</div> : null}
          <div className="panel side-panel">
            <h1 className="form-logo">
              <Logo />
            </h1>
            <div className="form-item">
              <label className="form-label" htmlFor="activation_code">{ dictionary.forms.fields.activationCode }*</label>
              <input
                type="text"
                id="activation_code"
                disabled={loading}
                className={classNames('form-input', {
                  'is-invalid': createUserForm.touched.activation_code && createUserForm.errors.activation_code
                })}
                {...createUserForm.getFieldProps('activation_code')}
              />
              {createUserForm.touched.activation_code && createUserForm.errors.activation_code ? (
                <span className="form-error" aria-live="polite">
                  {createUserForm.errors.activation_code}
                </span>
              ) : null}
            </div>
            <div className="form-grid">
              <div className="form-item">
                <label className="form-label" htmlFor="name">{ dictionary.forms.fields.lastName }*</label>
                <input
                  type="text"
                  id="name"
                  disabled={loading}
                  className={classNames('form-input', {
                    'is-invalid': createUserForm.touched.name && createUserForm.errors.name
                  })}
                  {...createUserForm.getFieldProps('name')}
                />
                {createUserForm.touched.name && createUserForm.errors.name ? (
                  <span className="form-error" aria-live="polite">
                    {createUserForm.errors.name}
                  </span>
                ) : null}
              </div>
              <div className="form-item">
                <label className="form-label" htmlFor="first_name">{ dictionary.forms.fields.firstName }*</label>
                <input
                  type="text"
                  id="first_name"
                  disabled={loading}
                  className={classNames('form-input', {
                    'is-invalid': createUserForm.touched.first_name && createUserForm.errors.first_name
                  })}
                  {...createUserForm.getFieldProps('first_name')}
                />
                {createUserForm.touched.first_name && createUserForm.errors.first_name ? (
                  <span className="form-error" aria-live="polite">
                    {createUserForm.errors.first_name}
                  </span>
                ) : null}
              </div>
            </div>
            <div className="form-item">
              <label className="form-label" htmlFor="email_address">{ dictionary.forms.fields.businessEmail }*</label>
              <input
                type="email"
                id="email_address"
                disabled={loading}
                className={classNames('form-input', {
                  'is-invalid': createUserForm.touched.email_address && createUserForm.errors.email_address
                })}
                {...createUserForm.getFieldProps('email_address')}
              />
              {createUserForm.touched.email_address && createUserForm.errors.email_address ? (
                <span className="form-error" aria-live="polite">
                  {createUserForm.errors.email_address}
                </span>
              ) : null}
            </div>
            <div className="form-item">
              <label className="form-label" htmlFor="job_title">{ dictionary.forms.fields.position }</label>
              <input
                type="text"
                id="job_title"
                disabled={loading}
                className={classNames('form-input', {
                  'is-invalid': createUserForm.touched.job_title && createUserForm.errors.job_title
                })}
                {...createUserForm.getFieldProps('job_title')}
              />
              {createUserForm.touched.job_title && createUserForm.errors.job_title ? (
                <span className="form-error" aria-live="polite">
                  {createUserForm.errors.job_title}
                </span>
              ) : null}
            </div>
            <div className="form-item">
              <label className="form-label" htmlFor="password_first">{ dictionary.forms.fields.password }*</label>
              <input
                type="password"
                id="password_first"
                disabled={loading}
                className={classNames('form-input', {
                  'is-invalid': createUserForm.touched.password_first && createUserForm.errors.password_first
                })}
                {...createUserForm.getFieldProps('password_first')}
              />
              {createUserForm.touched.password_first && createUserForm.errors.password_first ? (
                <span className="form-error" aria-live="polite">
                  {createUserForm.errors.password_first}
                </span>
              ) : null}
              {createUserForm.touched.password_first ? (
                !passwordValid ?
                  <span className="form-help">{ dictionary.forms.phrases.passwordHelp }</span> : <span className="form-success">{ dictionary.forms.phrases.passwordSecure }</span>
                ) : null
              }
            </div>
            <div className="form-item">
              <label className="form-label" htmlFor="password_second">{ dictionary.forms.fields.checkPassword }*</label>
              <input
                type="password"
                id="password_second"
                disabled={loading}
                className={classNames('form-input', {
                  'is-invalid': createUserForm.touched.password_second && createUserForm.errors.password_second
                })}
                {...createUserForm.getFieldProps('password_second')}
              />
              {createUserForm.touched.password_second && createUserForm.errors.password_second ? (
                <span className="form-error" aria-live="polite">
                  {createUserForm.errors.password_second}
                </span>
              ) : null}
            </div>
            <div className="form-buttons single-centered">
              <button type="submit" className="btn primary ghost" disabled={loading}>{ dictionary.forms.actions.register }</button>
            </div>
          </div>
        </form>
      </div>
      {
        showInactiveCode ? (
          <InactiveCode emailAddress={ambassadorEmail}>
            <form noValidate onSubmit={newCodeForm.handleSubmit}>
              <div className="form-item">
                <div className="activation-code">
                  <input
                    type="text"
                    id="activation_code"
                    placeholder={dictionary.forms.fields.typeNewCode}
                    disabled={loading}
                    className={classNames('form-input', {
                      'is-invalid': newCodeForm.touched.activation_code && newCodeForm.errors.activation_code
                    })}
                    {...newCodeForm.getFieldProps('activation_code')}
                  />
                  <button type="submit" className="btn-unstyled activate-code" disabled={loading} aria-label={dictionary.forms.actions.validate}></button>
                </div>
                {newCodeForm.touched.activation_code && newCodeForm.errors.activation_code ? (
                  <span className="form-error" aria-live="polite">
                    {newCodeForm.errors.activation_code}
                  </span>
                ) : null}
              </div>
            </form>
          </InactiveCode>
        ) : null
      }
      {
        showCodeOK ? (
          <Modal onClose={closeCodeOK}>
            <div className="modal-icon modal-success">
              <div className="icon" aria-label="✓"></div>
            </div>
            <div className="modal-title opacity">
              { dictionary.renewCodePopin.codeOK } { expiryDate }
            </div>
          </Modal>
        ) : null
      }
    </>
	)
}

export default Registration
