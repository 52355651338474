import React, { useContext, useEffect, useState } from 'react'

import classNames from 'classnames'
import Masonry from 'react-masonry-css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import ColorItem from './ColorItem'
import ImageItem from './ImageItem'
import LazyImage from '../../../../../Components/LazyImage'
import SelectTheme from './SelectTheme'

import { LanguageContext } from '../../../../../Contexts/Language'
import { downloadAssets, useAuthState, useAuthDispatch } from '../../../../../Contexts/MainContext'

const Downloads = ({ onClose, onThemeChange, book, currentTheme, currentContent }) => {

  const { userLanguage, dictionary } = useContext(LanguageContext)

  const dispatch = useAuthDispatch()
  const { loading, token } = useAuthState()

  const [fetching, setFetching] = useState(false)
  const [multiDownload, setMultiDownload] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])

  const [imgExpand, setImgExpand] = useState(false)
  const [imgExpandURL, setImgExpandURL] = useState()

  const startDownload = (url, name) => {
    if (!url) {
      throw new Error('Resource URL not provided! You need to provide one')
    }
    setFetching(true)
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        setFetching(false)
        const blobURL = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = blobURL
        a.style = 'display: none'

        if (name && name.length) a.download = name
        document.body.appendChild(a)
        a.click()
        killDownloadMode()
      })
      .catch(() => console.log('Fetch failed'))
  }

  const handleGetDownloadLink = async(payload) => {
    try {
      let response = await downloadAssets(dispatch, token, userLanguage, payload)
      if (!response) return
      if (response.code === 'OK') {
        startDownload(response.data.zip, book.title + '-assets.zip')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const removeItemOnce = (arr, value) => {
    var index = arr.indexOf(value)
    if (index > -1) {
      arr.splice(index, 1)
    }
    return arr
  }

  const killDownloadMode = () => {
    setMultiDownload(false)
    const emptySelectedImages = []
    setSelectedImages([...emptySelectedImages])
  }

  const handleDeSelectImage = (id) => {
    const newSelectedImages = removeItemOnce(selectedImages, id)
    setSelectedImages([...newSelectedImages])
  }
  
  const handleSelectImage = (id) => {
    if (!selectedImages.includes(id)) {
      setSelectedImages([...selectedImages, id])
    }
  }

  const handleDownloadAll = () => {
    const allAssets = []
    for (const theme in book.themes) {
      if (book.themes[theme].images.length) {
        for (const image in book.themes[theme].images) {
          allAssets.push(book.themes[theme].images[image].id)
        }
      }
      if (book.themes[theme].harmonies.length) {
        for (const harmony in book.themes[theme].harmony) {
          allAssets.push(book.themes[theme].harmony[harmony].id)
        }
      }
    }
    const payload = {
      "filename": book.title + '-assets',
      "ids": allAssets
    }
    handleGetDownloadLink(payload)
  }

  const handleDownload = () => {
    const payload = {
      "filename": book.title + '-assets',
      "ids": selectedImages
    }
    handleGetDownloadLink(payload)
  }

  const handleTabChange = () => {
    killDownloadMode()
  }

  const handleThemeChange = (page) => {
    killDownloadMode()
    onThemeChange(page)
  }

  const handleDownloadPanelClose = () => {
    killDownloadMode()
    onClose()
  }

  const handleOpenExpandImage = (imageURL) => {
    setImgExpand(true)
    setImgExpandURL(imageURL)
  }

  const handleCloseExpandImage = () => {
    setImgExpand(false)
    setImgExpandURL(null)
  }

  useEffect(() => {
    if (selectedImages.length > 0) {
      setMultiDownload(true)
    } else {
      setMultiDownload(false)
    }
  }, [selectedImages])

  return (
    <>
      <div
        className="pdf-viewer--downloads--close-overlay"
        onClick={handleDownloadPanelClose}
      ></div>
      <div className="pdf-viewer--downloads--panel">
        <div className="scroll">
          {/* CLOSE BUTTON */}
          <button
            type="button"
            className="btn-unstyled pdf-viewer--btn pdf-viewer--btn-close"
            onClick={handleDownloadPanelClose}
          ><span className="sr-only">{dictionary.buttons.close}</span></button>
          {/* TITLE */}
          <div className="pdf-viewer--downloads--title">{ dictionary.trendBooks.downloads.title }</div>
          {/* ACTIONS BAR */}
          <div className="pdf-viewer--downloads--actions">
            {/* THEME SELECTOR */}
            <div className="pdf-viewer--downloads--themes">
              <div className="pdf-viewer--downloads--themes--title">{ dictionary.trendBooks.downloads.chapter }</div>
              <SelectTheme onChange={handleThemeChange} options={book.themes} currentThemeID={currentTheme} />
            </div>
            {/* 'DOWNLOAD ALL' BUTTON */}
            <button
              type="button"
              disabled={fetching || loading}
              className="btn secondary ghost pdf-viewer--btn-download-all"
              onClick={handleDownloadAll}
            >
              { dictionary.trendBooks.downloads.all }
            </button>
          </div>
          {/* TABS */}
          <Tabs
            className="pdf-viewer--downloads--tabs"
            selectedTabClassName="pdf-viewer--downloads--tab--active"
            selectedTabPanelClassName="pdf-viewer--downloads--tab-panel--active"
            onSelect={handleTabChange}
          >
            {/* TAB BUTTONS */}
            <div className="pdf-viewer--downloads--tab-list-container">
              <TabList className="pdf-viewer--downloads--tab-list">
                {
                  currentContent.images.length ? (
                    <Tab className="pdf-viewer--downloads--tab">
                      Images
                    </Tab>
                  ) : null
                }
                {
                  currentContent.colors.length ? (
                    <Tab className="pdf-viewer--downloads--tab">
                      { dictionary.trendBooks.downloads.colors }
                    </Tab>
                  ) : null
                }
                {
                  currentContent.harmonies.length ? (
                    <Tab className="pdf-viewer--downloads--tab">
                      Harmonies
                    </Tab>
                  ) : null
                }
                {
                  currentContent.sketches.length ? (
                    <Tab className="pdf-viewer--downloads--tab">
                      Flat sketches
                    </Tab>
                  ) : null
                }
              </TabList>
            </div>
            {/* TAB PANELS */}
            {
              currentContent.images.length ? (
                <TabPanel
                  className={classNames('pdf-viewer--downloads--tab-panel', {'pdf-viewer--multi-download--activated': multiDownload})}
                >
                  <Masonry
                    breakpointCols={{
                      default: 2,
                      767: 4
                    }}
                    className="pdf-viewer--downloads--image-grid"
                    columnClassName="pdf-viewer--downloads--image-grid-column">
                    {
                      Object.keys(currentContent.images).map((key, i) => (
                        <ImageItem
                          key={i}
                          selectImage={handleSelectImage}
                          deSelectImage={handleDeSelectImage}
                          expandImage={handleOpenExpandImage}
                          imageData={currentContent.images[key]}
                          unCheckAll={!multiDownload}
                        />
                      ))
                    }
                  </Masonry>
                </TabPanel>
              ) : null
            }
            {
              currentContent.colors.length ? (
                <TabPanel className="pdf-viewer--downloads--tab-panel">
                  <div className="pdf-viewer--downloads--color-list">
                    {
                      Object.keys(currentContent.colors).map((key, i) => (
                        <ColorItem key={i} colorData={currentContent.colors[key]} />
                      ))
                    }
                  </div>
                </TabPanel>
              ) : null
            }
            {
              currentContent.harmonies.length ? (
                <TabPanel
                  className={classNames('pdf-viewer--downloads--tab-panel', {'pdf-viewer--multi-download--activated': multiDownload})}
                >
                  <div className="pdf-viewer--downloads--harmony-list">
                    {
                      Object.keys(currentContent.harmonies).map((key, i) => (
                        <ImageItem 
                          key={i}
                          selectImage={handleSelectImage}
                          deSelectImage={handleDeSelectImage}
                          expandImage={handleOpenExpandImage}
                          imageData={currentContent.harmonies[key]}
                          unCheckAll={!multiDownload}
                        />
                      ))
                    }
                  </div>
                </TabPanel>
              ) : null
            }
            {
              currentContent.sketches.length ? (
                <TabPanel className="pdf-viewer--downloads--tab-panel">
                  <table className="pdf-viewer--downloads--flat-sketches-table">
                    <tbody>
                      {
                        Object.keys(currentContent.sketches).map((key, i) => (
                          <tr key={i}>
                            <td>
                              {
                                currentContent.sketches[key].file.indexOf('.ai') > -1 ? (
                                  <div className="pdf-viewer--downloads--flat-sketches-table--file-type">
                                    Illustrator
                                  </div>
                                ) : <div className="pdf-viewer--downloads--flat-sketches-table--file-type">
                                  Photoshop
                                </div>
                              }
                              {currentContent.sketches[key].file.substring(currentContent.sketches[key].file.lastIndexOf('/') + 1)}
                            </td>
                            <td>
                              <div className="pdf-viewer--downloads--flat-sketches-table--desc">
                                {currentContent.sketches[key].desc}
                              </div>
                            </td>
                            <td>
                              <a href={currentContent.sketches[key].file} download className="pdf-viewer--downloads--flat-sketches-table--btn-download"><span className="sr-only">{dictionary.buttons.download}</span></a>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </TabPanel>
              ) : null
            }
          </Tabs>
          <div
            className={classNames('pdf-viewer--multi-download--status', {'show': multiDownload})}
          >
            { selectedImages.length.toString() }
            {
              selectedImages.length > 1 ? (
                dictionary.trendBooks.downloads.selectedItems
              ) : (
                dictionary.trendBooks.downloads.selectedItem
              )
            }
            <button
              type="button"
              disabled={fetching || loading}
              className="btn secondary pdf-viewer--multi-download--button"
              onClick={handleDownload}
            >
              { dictionary.trendBooks.downloads.download }
            </button>
          </div>
          <div className={classNames('pdf-viewer--multi-download--overlay', {'show': fetching || loading})}>
            <div className="inner">
              <svg className="rpv-core__spinner" width="64px" height="64px" viewBox="0 0 32 32"><circle className="rpv-core__spinner-circle" cx="16" cy="16" r="12" strokeDasharray="56.548667764616276"></circle></svg>
            </div>
          </div>
        </div>
      </div>
      {
        imgExpand && (
          <div className="pdf-viewer--downloads--img-expand">
            { imgExpandURL && <LazyImage src={imgExpandURL} alt={''} /> }
            <button
              type="button"
              className="btn-unstyled pdf-viewer--btn-close"
              onClick={handleCloseExpandImage}
            ><span className="sr-only">{dictionary.buttons.close}</span></button>
          </div>
        )
      }
    </>
  )
}

export default Downloads
