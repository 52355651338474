import React, { useContext, useState } from 'react'

import { LanguageContext } from '../Contexts/Language'

function DownloadButton({cssClasses, url, filename}) {

  const { dictionary } = useContext(LanguageContext)

  const [fetching, setFetching] = useState(false)

  const handleDownload = (url, name) => {
    if (!url) {
      throw new Error('Resource URL not provided! You need to provide one')
    }
    setFetching(true)
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        setFetching(false)
        const blobURL = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = blobURL
        a.style = 'display: none'

        if (name && name.length) a.download = name
        document.body.appendChild(a)
        a.click()
      })
      .catch(() => console.log('Fetch failed'))
  }

  return (
    <button
      className={cssClasses}
      disabled={fetching}
      onClick={()=> handleDownload(url, filename)}
    ><span className="sr-only">{dictionary.buttons.download}</span></button>
  )
}

export default DownloadButton
