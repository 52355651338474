// logo
import React from 'react'

import logo from '../assets/images/peclersplus-logo.png'
import retinaLogo from '../assets/images/peclersplus-logo@2x.png'

function Logo() {
	return (
    <img src={logo} srcSet={`${logo} 1x, ${retinaLogo} 2x`} alt="Peclers+" />
	)
}

export default Logo
