// trend book thumbnail
import React, { useContext } from 'react'

import { Link } from 'react-router-dom'

import LazyImage from './LazyImage'

import { LanguageContext } from '../Contexts/Language'

const TrendBookThumbnail = (props) => {

  const { dictionary } = useContext(LanguageContext)

  const book = props.book
  const altImg = book.title + ' ' + book.season

  return (
    <div>
      <Link to={'/trend-book/' + book.id} className="trend-book--thumbnail">
        <LazyImage className="trend-book--thumbnail--image" src={book.image} alt={altImg} aspectRatio={75 / 112} />
        {
          book.new ? (
            <div className="trend-book--thumbnail--is-new">{ dictionary.general.new }</div>
          ) : null
        }
        <div className="trend-book--thumbnail--text">
          <div className="trend-book--thumbnail--title">
            { book.title }
          </div>
          <div className="trend-book--thumbnail--season">
            { book.season }
          </div>
        </div>
      </Link>
    </div>
  )
}

export default TrendBookThumbnail
