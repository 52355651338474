// page header
import React from 'react'

import { Link } from 'react-router-dom'

const Header = ({ title, text, linkUrl, linkText, children }) => {
  return (
    <div className="page-header">
      {
        title ? (
          <h1 className="lvl1-title page-header--title">{ title }</h1>
        ) : null
      }
      {
        text ? (
          <p className="page-header--text">
            { text }
          </p>
        ) : null
      }
      {
        linkUrl && linkText ? (
          <Link to={ linkUrl } className="arrow-btn arrow-fwd primary page-header--link">{ linkText }</Link>
        ) : null
      }
      { children }
    </div>
  )
}

export default Header
