// login page

import React, { useContext, useState } from 'react'

import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'

import readableDate from '../../../Utilities/readableDate'

import { loginUser, getUser, getRunwayShowSeasons, activationCode, useAuthState, useAuthDispatch } from '../../../Contexts/MainContext'
import { LanguageContext } from '../../../Contexts/Language'

import ContactForm from '../../../Components/ContactForm'
import InactiveCode from '../../../Components/InactiveCode'
import LanguageSelector from '../../../Components/LanguageSelector'
import Logo from '../../../Components/Logo'
import Modal from '../../../Components/Modal'

function Login(props) {

	const { userLanguage, dictionary } = useContext(LanguageContext)

	const dispatch = useAuthDispatch()
	const { errorMessage, loading, token } = useAuthState()

	const [ambassadorEmail, setAmbassadorEmail] = useState('')
	const [showInactiveCode, setShowInactiveCode] = useState(false)

	const [showCodeOK, setShowCodeOK] = useState(false)
	const [expiryDate, setExpiryDate] = useState('')

	const [showContactForm, setShowContactForm] = useState(false)
	const [showContactFormSuccess, setShowContactFormSuccess] = useState(false)

	const loginForm = useFormik({
		initialValues: {
			email: '',
			password: ''
		},
		validate: values => {
			const errors = {}
			if (!values.email) {
				errors.email = dictionary.forms.errors.noEmail
			}
			if (!values.password) {
				errors.password = dictionary.forms.errors.noPassword
			}

			return errors
		},
		onSubmit: async (values) => {
			try {
				let loginResponse = await loginUser(dispatch, values)
				if (!loginResponse) return

				if (loginResponse.token) {
					let getUserResponse = await getUser(dispatch, loginResponse.token)
					if (!getUserResponse.id) return

					if (getUserResponse.company.activation_code_status === 'inactive') {
						setAmbassadorEmail(getUserResponse.ambassador.email_address)
						setShowInactiveCode(!showInactiveCode)
					} else {
						try {
							let getSeasonsResponse = await getRunwayShowSeasons(dispatch, loginResponse.token, userLanguage)
							if (getSeasonsResponse.code !== 'OK') return

							props.history.push('/')

						} catch (error) {
							console.log(error)
						}
					}
				}
			} catch (error) {
				console.log(error)
			}
		},
	})

	const newCodeForm = useFormik({
		initialValues: {
			activation_code: ''
		},
		validate: values => {
			const errors = {}
			if (!values.activation_code) {
				errors.activation_code = dictionary.forms.errors.noCode
			}

			return errors
		},
		onSubmit: async (values, actions) => {
			try {
				let sendNewCodeResponse = await activationCode(dispatch, values, token)
				if (!sendNewCodeResponse) return

				if (sendNewCodeResponse.email_address) {
					const date = readableDate(sendNewCodeResponse.data.company.activation_code_limit_date, userLanguage)
          setExpiryDate(date)
					setShowCodeOK(!showCodeOK)
				} else if (sendNewCodeResponse.code === 'MISSING_ACTIVATION_CODE') {
					actions.setFieldError('activation_code', dictionary.renewCodePopin.missingCodeError)
				} else if (sendNewCodeResponse.code === 'INVALID_ACTIVATION_CODE') {
					actions.setFieldError('activation_code', dictionary.renewCodePopin.invalidCodeError)
				}
			} catch (error) {
				console.log(error)
			}
		},
	})

	const closeCodeOK = () => {
		setShowCodeOK(false)
		props.history.push('/')
	}

	const handleCloseContactFormSuccess = () => {
		setShowContactFormSuccess(false)
		setShowContactForm(false)
	}

	return (
		<>
			<Helmet>
				<html lang={userLanguage} />
			</Helmet>
			<div className="landing-page">
				<LanguageSelector hidden={true} />
				<div className="login-panel">
					<form noValidate onSubmit={loginForm.handleSubmit}>
						{errorMessage ? <div className="panel-addon error">{errorMessage}</div> : null}
						<div className="panel">
							<h1 className="form-logo">
								<Logo />
							</h1>
							<div className="form-item">
								<label className="form-label" htmlFor="email">{dictionary.forms.fields.email}</label>
								<input
									type="email"
									id="email"
									disabled={loading}
									className={classNames('form-input', {
										'is-invalid': loginForm.touched.email && loginForm.errors.email
									})}
									{...loginForm.getFieldProps('email')}
								/>
								{loginForm.touched.email && loginForm.errors.email ? (
									<span className="form-error" aria-live="polite">
										{loginForm.errors.email}
									</span>
								) : null}
							</div>
							<div className="form-item">
								<label className="form-label" htmlFor="password">{dictionary.forms.fields.password}</label>
								<input
									type="password"
									id="password"
									disabled={loading}
									className={classNames('form-input', {
										'is-invalid': loginForm.touched.password && loginForm.errors.password
									})}
									{...loginForm.getFieldProps('password')}
								/>
								{loginForm.touched.password && loginForm.errors.password ? (
									<span className="form-error" aria-live="polite">
										{loginForm.errors.password}
									</span>
								) : null}
							</div>
							<div className="form-buttons">
								<button type="submit" className="btn primary ghost" disabled={loading}>{dictionary.forms.actions.logIn}</button>
								<Link to="/lost-password" className="btn-unstyled forgot-pwd-link">{dictionary.forms.actions.passwordForgotten}</Link>
							</div>
						</div>
						<div className="panel-addon">
							<div className="form-buttons login-form-etudiant">
								<p className="text">{dictionary.forms.phrases.ImAStudent}</p>
								<Link to="/registration" className="arrow-btn arrow-fwd primary small">{dictionary.forms.actions.subscribe}</Link>
							</div>
						</div>
						<div className="panel-addon small text-center">
							<p>
								{dictionary.forms.phrases.notRegisteredYet} <button type="button" className="btn-unstyled link-btn" onClick={() => setShowContactForm(true)}>{dictionary.forms.actions.contactUs}</button>
							</p>
						</div>
					</form>
				</div>
				{
					showInactiveCode ? (
						<InactiveCode emailAddress={ambassadorEmail}>
							<form noValidate onSubmit={newCodeForm.handleSubmit}>
								<div className="form-item">
									<div className="activation-code">
										<input
											type="text"
											id="activation_code"
											placeholder={dictionary.forms.fields.typeNewCode}
											disabled={loading}
											className={classNames('form-input', {
												'is-invalid': newCodeForm.touched.activation_code && newCodeForm.errors.activation_code
											})}
											{...newCodeForm.getFieldProps('activation_code')}
										/>
										<button type="submit" className="btn-unstyled activate-code" disabled={loading} aria-label={dictionary.forms.actions.validate}></button>
									</div>
									{newCodeForm.touched.activation_code && newCodeForm.errors.activation_code ? (
										<span className="form-error" aria-live="polite">
											{newCodeForm.errors.activation_code}
										</span>
									) : null}
								</div>
							</form>
						</InactiveCode>
					) : null
				}
				{
					showCodeOK ? (
						<Modal onClose={closeCodeOK}>
							<div className="modal-icon modal-success">
								<div className="icon" aria-label="✓"></div>
							</div>
							<div className="modal-title opacity">
								{ dictionary.renewCodePopin.codeOK } { expiryDate }
							</div>
						</Modal>
					) : null
				}
				{
					showContactForm && <ContactForm onClose={() => setShowContactForm(false)} onSuccess={() => setShowContactFormSuccess(true) } />
				}
        {
          showContactFormSuccess && (
            <Modal onClose={handleCloseContactFormSuccess}>
              <div className="modal-icon modal-success">
                <div className="icon" aria-label="✓"></div>
              </div>
              <div className="modal-text">
                { dictionary.contact.success }
              </div>
            </Modal>
          )
        }
				<a href="https://beian.miit.gov.cn/" className="icp-link" target="_blank" rel="noopener noreferrer">沪ICP备2020025522号-2</a>
			</div>
		</>
	)
}

export default Login
