// account management (activation codes, members list)
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import { Formik } from 'formik'

import ContactForm from '../../../Components/ContactForm'
import Modal from '../../../Components/Modal'

import readableDate from '../../../Utilities/readableDate'

import { LanguageContext } from '../../../Contexts/Language'
import { addMember, deleteMember, getActivationCodes, getMembers, getUser, logout, useAuthDispatch, useAuthState } from '../../../Contexts/MainContext'

function Subscribers() {

  const { dictionary } = useContext(LanguageContext)
  const dispatch = useAuthDispatch()
  const { errorMessage, loading, token, user } = useAuthState()

  const [activationCodes, setActivationCodes] = useState()
  const [addedUserEmail, setAddedUserEmail] = useState()
  const [addUserError, setAddUserError] = useState()
  const [hasMore, setHasMore] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [nextPage, setNextPage] = useState()
  const [membersList, setMembersList] = useState([])
  const [totalMembers, setTotalMembers] = useState(0)
  const [userToDelete, setUserToDelete] = useState()

  const [showAddUserForm, setShowAddUserForm] = useState(false)
  const [showAddUserSuccess, setShowAddUserSuccess] = useState(false)
  const [showCannotAddUser, setShowCannotAddUser] = useState(false)
  const [showContactForm, setShowContactForm] = useState(false)
	const [showContactFormSuccess, setShowContactFormSuccess] = useState(false)
  const [showDeleteUser, setShowDeleteUser] = useState(false)
  const [showUserAlreadyExists, setShowUserAlreadyExists] = useState(false)

  const currentCode = useRef(null)
  const oldCode = useRef(null)

  const per_page = 10

  const addUser = () => {
    if (user.company.nb_use_licenses === user.company.nb_open_licenses) {
      setShowCannotAddUser(true)
    } else {
      openAddUserForm()
    }
  }

  const deleteUser = async (id) => {
    try {
      let response = await deleteMember(dispatch, token, id)
      if (!response) return
      closeDeleteUser()
      try {
        let getUserResponse = await getUser(dispatch, token)
        if (!getUserResponse.id) return
      } catch (error) {
        console.log(error)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetMembers = useCallback(async (page) => {
    const payload = {
      "sort_by": "fullname",
      "sort_order": "ASC",
      "per_page": per_page,
      "page": page,
    }
    try {
      setIsLoading(true)
      if (page === 1) {
        setMembersList([])
      }
      let response = await getMembers(dispatch, token, payload)
      if (!response) return
      if (response.code === 'OK') {
        setIsLoading(false)
        if (response.data.accounts.length) {
          const newList = Object.values(response.data.accounts)
          setMembersList(membersList => membersList.concat(newList))
          setNextPage(page + 1)
          setTotalMembers(parseInt(response.data.total))
          if (response.data.accounts.length < per_page) {
            setHasMore(false)
          }
        } else {
          setHasMore(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }, [token, dispatch])

  const handleGetActivationCodes = useCallback(async () => {
    try {
      let response = await getActivationCodes(dispatch, token)
      if (!response) return
      setActivationCodes(response.data)
    } catch (error) {
      console.log(error)
    }
  }, [token, dispatch])

  const loadMore = () => {
    handleGetMembers(nextPage)
  }

  const handleClick = () => {
    if (isLoading || !hasMore || user.company.type === 'university') return
    loadMore()
  }

  const openAddUserForm = () => {
    setShowAddUserForm(true)
  }

  const closeAddUserForm = () => {
    setShowAddUserForm(false)
    setAddUserError(null)
  }

  const closeDeleteUser = () => {
    setShowDeleteUser(false)
  }

  const closeAddUserSuccess = () => {
    setShowAddUserSuccess(false)
  }

  const handleCloseContactFormSuccess = () => {
		setShowContactFormSuccess(false)
		setShowContactForm(false)
	}

  useEffect(() => {
    if (user.company.type === 'university') {
      handleGetActivationCodes()
    }
    if (user.company.type === 'standard' && user.ambassador_account) {
      setHasMore(true)
      handleGetMembers(1)
    }
  }, [user, handleGetActivationCodes, handleGetMembers])

  return (
    user.company.type === 'university' ? (
      activationCodes ? (
        <div className="subscribers-university">
          <div className="activation-code-box">
            <div className="activation-code-box--text">
              <h2 className="activation-code-box--title">
                {
                  !activationCodes.depreciated_activation_code || activationCodes.depreciated_activation_code === '' ? dictionary.profile.universitySubscribers.activationCodeTitle : dictionary.profile.universitySubscribers.newActivationCodeTitle
                }
              </h2>
              <p className="activation-code-box--info">
                {
                  !activationCodes.depreciated_activation_code || activationCodes.depreciated_activation_code === '' ? dictionary.profile.universitySubscribers.activationCodeText1 : dictionary.profile.universitySubscribers.newActivationCodeText1
                }
                <br/>
                { dictionary.profile.universitySubscribers.activationCodeText2 } { readableDate(activationCodes.activation_code_date_limit) }
              </p>
            </div>
            <div className="activation-code-box--display">
              <div className="activation-code-box--code">
                <mark ref={currentCode}>{ activationCodes.activation_code }</mark>
              </div>
              <button type="button" className="btn-unstyled activation-code-box--btn-copy tap-expand-after" onClick={() => {navigator.clipboard.writeText(currentCode.current.textContent)}}>{ dictionary.buttons.copy }</button>
            </div>
          </div>
          {
            activationCodes.depreciated_activation_code && activationCodes.depreciated_activation_code !== '' ? (
              <div className="activation-code-box">
                <div className="activation-code-box--text">
                  <h2 className="activation-code-box--title">
                    { dictionary.profile.universitySubscribers.deprecatedActivationCodeTitle }
                  </h2>
                  <p className="activation-code-box--info">
                    { dictionary.profile.universitySubscribers.deprecatedActivationCodeText1 } { readableDate(activationCodes.depreciated_activation_code_date) } { dictionary.profile.universitySubscribers.deprecatedActivationCodeText2 } <br className="visible-sm"/> { readableDate(activationCodes.depreciated_activation_code_date_limit) } { dictionary.profile.universitySubscribers.deprecatedActivationCodeText3 }.
                  </p>
                </div>
                <div className="activation-code-box--display">
                  <div className="activation-code-box--code deprecated">
                    <mark ref={oldCode}>{ activationCodes.depreciated_activation_code }</mark>
                  </div>
                  <button type="button" className="btn-unstyled activation-code-box--btn-copy tap-expand-after" onClick={() => {navigator.clipboard.writeText(oldCode.current.textContent)}}>{ dictionary.buttons.copy }</button>
                </div>
              </div>
            ) : null
          }
        </div>
      ) : null
    ) : (
      <div className="subscribers-standard">
        <div className="subscribers-standard--add-user">
          <button type="button" className="btn-unstyled subscribers-standard--btn-add" onClick={addUser}>{ dictionary.profile.standardSubscribers.actions.addUser }&nbsp;+</button>
          <span className="subscribers-standard--user-count">{ user.company.nb_use_licenses }/{user.company.nb_open_licenses}</span>
        </div>
        {
          membersList.length ? (
            <table className="subscribers-standard--members-list">
              <thead>
                {
                  membersList[0].activation_code_date ? (
                    <tr>
                      <th>
                        { dictionary.profile.standardSubscribers.membersList.user }
                      </th>
                      <th colSpan="2">
                        { dictionary.profile.standardSubscribers.membersList.availability }
                      </th>
                    </tr>
                  ) : (
                    <tr>
                      <th colSpan="2">
                        { dictionary.profile.standardSubscribers.membersList.user }
                      </th>
                    </tr>
                  )
                }
              </thead>
              <tbody>
                {
                  membersList.map(function(member) {
                    return (
                      <tr key={member.id}>
                        <td className="subscribers-standard--members-list--name">
                          { member.first_name } { member.name }
                        </td>
                        {
                          member.activation_code_date ? (
                            <td className="subscribers-standard--members-list--date">
                              { readableDate(member.activation_code_date) }
                            </td>
                          ) : null
                        }
                        <td className="td-btn">
                          <button type="button" className="btn-unstyled subscribers-standard--members-list--btn-delete" data-id={member.id} onClick={() => { setUserToDelete(member.id); setShowDeleteUser(true) } }>{ dictionary.profile.standardSubscribers.actions.delete }</button>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          ) : null
        }
        {
          showAddUserForm ? (
            <Modal onClose={closeAddUserForm} errorMessage={addUserError}>
              <div className="modal-title">
                { dictionary.profile.standardSubscribers.inviteUser }
              </div>
              <Formik
                initialValues={{
                  name: '',
                  first_name: '',
                  email_address: '',
                  job_title: ''
                }}
                validate={values => {
                  const errors = {}
                  if (!values.name) {
                    errors.name = dictionary.forms.errors.noLastName
                  }
                  if (!values.first_name) {
                    errors.first_name = dictionary.forms.errors.noFirstName
                  }
                  if (!values.email_address) {
                    errors.email_address = dictionary.forms.errors.noEmail
                  } else if (!/\b[\w\.-]+@[\w\.-]+\.\w{2,}\b/gi.test(values.email_address)) {
                    errors.email_address = dictionary.forms.errors.invalidEmail
                  }

                  return errors
                }}
                onSubmit={async (values) => {
                  try {
                    let response = await addMember(dispatch, token, values)
                    if (!response.code) return

                    if (response.code === 'OK') {
                      setAddedUserEmail(values.email_address)
                      closeAddUserForm()
                      setShowAddUserSuccess(true)
                      try {
                        let getUserResponse = await getUser(dispatch, token)
                        if (!getUserResponse.id) return
                      } catch (error) {
                        console.log(error)
                      }
                    } else if (response.code === 'USER_LIMIT_REACHED') {
                      closeAddUserForm()
                      setShowCannotAddUser(true)
                    } else if (response.code === 'ACCOUNT_ALREADY_EXISTS') {
                      setShowUserAlreadyExists(true)
                    } else {
                      logout(dispatch)
                    }
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                {({
                  dirty,
                  errors,
                  getFieldProps,
                  handleSubmit,
                  isValid,
                  touched
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <div className="form-item">
                      <label className="form-label" htmlFor="first_name">{ dictionary.forms.fields.firstName }</label>
                      <input
                        type="text"
                        id="first_name"
                        disabled={loading}
                        className={classNames('form-input', {
                          'is-invalid': touched.first_name && errors.first_name
                        })}
                        {...getFieldProps('first_name')}
                      />
                      {touched.first_name && errors.first_name ? (
                        <span className="form-error" aria-live="polite">
                          {errors.first_name}
                        </span>
                      ) : null}
                    </div>
                    <div className="form-item">
                      <label className="form-label" htmlFor="name">{ dictionary.forms.fields.lastName }</label>
                      <input
                        type="text"
                        id="name"
                        disabled={loading}
                        className={classNames('form-input', {
                          'is-invalid': touched.name && errors.name
                        })}
                        {...getFieldProps('name')}
                      />
                      {touched.name && errors.name ? (
                        <span className="form-error" aria-live="polite">
                          {errors.name}
                        </span>
                      ) : null}
                    </div>
                    <div className="form-item">
                      <label className="form-label" htmlFor="email_address">{ dictionary.forms.fields.businessEmail }</label>
                      <input
                        type="email"
                        id="email_address"
                        disabled={loading}
                        className={classNames('form-input', {
                          'is-invalid': touched.email_address && errors.email_address
                        })}
                        {...getFieldProps('email_address')}
                      />
                      {touched.email_address && errors.email_address ? (
                        <span className="form-error" aria-live="polite">
                          {errors.email_address}
                        </span>
                      ) : null}
                    </div>
                    <div className="form-buttons single-centered">
                      <button type="submit" className="btn primary ghost" disabled={loading || !(isValid && dirty)}>{dictionary.forms.actions.send}</button>
                    </div>
                  </form>
                )}
              </Formik>
            </Modal>
          ) : null
        }
        {
          showCannotAddUser ? (
            <Modal largeModal={true} onClose={() => setShowCannotAddUser(false) }>
              <div className="modal-icon modal-error">
                <div className="icon" aria-label="!"></div>
                <div className="text">{ dictionary.tokenExpired.oops }</div>
              </div>
              <div className="modal-text">
                { dictionary.profile.standardSubscribers.cannotAddUser.text1 }<br/>
                { dictionary.profile.standardSubscribers.cannotAddUser.text2 }
              </div>
              <div className="modal-text text-center">
                <button type="button" className="btn primary" onClick={() => { setShowCannotAddUser(false); setShowContactForm(true) }}>Contact</button>
              </div>
            </Modal>
          ) : null
        }
        {
          showUserAlreadyExists ? (
            <Modal onClose={() => setShowUserAlreadyExists(false) }>
              <div className="modal-icon modal-error">
                <div className="icon" aria-label="!"></div>
              </div>
              <div className="modal-text">
                { dictionary.profile.standardSubscribers.cannotAddUser.alreadyExists }
              </div>
            </Modal>
          ) : null
        }
        {
          showAddUserSuccess ? (
            <Modal onClose={closeAddUserSuccess}>
              <div className="modal-icon modal-success">
                <div className="icon" aria-label="✓"></div>
                <div className="text">{ dictionary.profile.standardSubscribers.addUser.title }</div>
              </div>
              <div className="modal-text">
                { dictionary.profile.standardSubscribers.addUser.text1 } { addedUserEmail } { dictionary.profile.standardSubscribers.addUser.text2 }
              </div>
              <div className="modal-text text-center">
                <button 
                  type="button"
                  className="btn primary"
                  onClick={() => {
                    closeAddUserSuccess();
                    openAddUserForm()
                  }}
                >
                  { dictionary.profile.standardSubscribers.actions.addUser }
                </button>
              </div>
            </Modal>
          ) : null
        }
				{
					showDeleteUser ? (
						<Modal onClose={closeDeleteUser} errorMessage={errorMessage}>
							<div className="modal-title">
                { dictionary.profile.standardSubscribers.deleteUser.title }
              </div>
              <div className="btn-container horizontal">
                <button
                  type="button"
                  className="btn primary"
                  onClick={() => {
                    deleteUser(userToDelete);
                  }}
                >
                  { dictionary.profile.standardSubscribers.deleteUser.yes }
                </button>
                <button
                  type="button"
                  className="btn primary ghost"
                  onClick={closeDeleteUser}
                >
                  { dictionary.profile.standardSubscribers.deleteUser.no }
                </button>
              </div>
						</Modal>
					) : null
				}
				{
          showContactForm && <ContactForm onClose={() => setShowContactForm(false)} onSuccess={() => setShowContactFormSuccess(true) } />
        }
        {
          showContactFormSuccess && (
            <Modal onClose={handleCloseContactFormSuccess}>
              <div className="modal-icon modal-success">
                <div className="icon" aria-label="✓"></div>
              </div>
              <div className="modal-text">
                { dictionary.contact.success }
              </div>
            </Modal>
          )
        }
        {
          totalMembers - 1 > membersList.length ? (
            <div className="btn-container horizontal subscribers-standard--load-more">
              <button type="button" onClick={handleClick} className="btn primary">{dictionary.buttons.showMore}</button>
            </div>
          ) : null
        }
      </div>
    )
  )
}

export default Subscribers
