// 404 page
import React, { useContext } from 'react'

import ExpiredCode from '../../Components/ExpiredCode'
import Header from '../../Components/Header'
import Nav from '../../Components/Nav'

import { LanguageContext } from '../../Contexts/Language'

function NotFound(props) {

	const { dictionary } = useContext(LanguageContext)

	return (
		<div className="page">
			<ExpiredCode />
			<Nav {...props} />
			<div className="main-content">
				<Header title={ dictionary.notFound.title } text={ dictionary.notFound.text } linkUrl={'/'} linkText={ dictionary.notFound.linkText } />
			</div>
		</div>
	)
}

export default NotFound
