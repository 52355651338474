// screen size media query 
// see: https://medium.com/@alexander_15201/react-screen-sizes-device-types-and-media-queries-ad304caad160

import { useLayoutEffect, useState } from 'react'

export default function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0])

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateScreenSize)
    updateScreenSize()
    return () => window.removeEventListener('resize', updateScreenSize)
  }, [])

  return screenSize
}
