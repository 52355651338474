// runway shome theme thumbnail (with carousel gallery)
import React from 'react'

import { Link } from 'react-router-dom'
import Slider from 'react-slick'

import LazyImage from './LazyImage'

import 'slick-carousel/slick/slick.css'

export default function RunwayShowThemeItem({item, seasonID, categoryID, category, section}) {

	const sliderSettings = {
    draggable: false,
    dots: true,
    infinite: false,
    swipe: false
  }

  return (
    <div className="runway-show--theme--item">
      <Link
        to={ '/runway-shows/' + seasonID + '/' + categoryID + '/' + section.id } className="runway-show--theme--item"
      >
        <Slider className="runway-show--theme--gallery" {...sliderSettings}>
          {
            Object.keys(item.galeries).map((key, i) => (
              <div key={i}>
                <div className="runway-show--theme--gallery--city">
                  {
                    category ? (
                      category
                    ) : (
                      item.galeries[key].city.name
                    )
                  }
                </div>
                <LazyImage
                  key={i}
                  src={item.galeries[key].image_thumbnail || item.galeries[key].thumbnail }
                  alt={ item.galeries[key].city.name }
                  aspectRatio={262/391}
                />
              </div>
            ))
          }
        </Slider>
        {section.title ? <div className="runway-show--theme--item--section">{section.title}</div> : null}
        {item.thematic ? <div className="runway-show--theme--item--theme">{item.thematic}</div> : null}
      </Link>
    </div>
  )
}
