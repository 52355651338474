// for changing one's password from outside the private app

import React, { useContext, useState } from 'react'

import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useLocation } from 'react-router-dom'

import { changePassword, useAuthState, useAuthDispatch } from '../../../Contexts/MainContext'
import { LanguageContext } from '../../../Contexts/Language'

import LanguageSelector from '../../../Components/LanguageSelector'
import Modal from '../../../Components/Modal'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function ChangePassword() {

	const { userLanguage, dictionary } = useContext(LanguageContext)

	const dispatch = useAuthDispatch()
	const { errorMessage, loading } = useAuthState()

	const pw_token = useQuery().get('pw_token')

  const [showEditSuccess, setShowEditSuccess] = useState(false)

  const [formError, setFormError] = useState()
  const [passwordValid, setPasswordValid] = useState(false)

	const formik = useFormik({
		initialValues: {
			password_token: pw_token,
			new_password_first: '',
			new_password_second: ''
		},
		validate: values => {
			const errors = {}

			if (!values.password_token) {
				setFormError(dictionary.forms.errors.passwordChangeForbidden)
			} else {
				setFormError()
			}
			if (!values.new_password_first) {
				errors.new_password_first = dictionary.forms.errors.noPasswordFirst
			} else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/.test(values.new_password_first)) {
				setPasswordValid(false)
				errors.new_password_first = dictionary.forms.errors.invalidPassword
			} else {
				setPasswordValid(true)
			}
			if (!values.new_password_second) {
				errors.new_password_second = dictionary.forms.errors.noPasswordSecond
			} else if (values.new_password_second !== values.new_password_first) {
				errors.new_password_second = dictionary.forms.errors.passwordMismatch
			}
	
			return errors
		},
		onSubmit: async (values) => {
			try {
				let response = await changePassword(dispatch, values)
				if (!response.code) return

				if (response.code === 'EXPIRED_PASSWORD_TOKEN') {
					setFormError(dictionary.forms.errors.expiredToken)
				} else if (response.code === 'OK') {
					setFormError()
					setShowEditSuccess(true)
				}
			} catch (error) {
				console.log(error)
			}
		},
	})

	return (
		<>
			<Helmet>
				<html lang={userLanguage} />
			</Helmet>
			<div className="landing-page">
				<LanguageSelector hidden={true} />
				<div className="login-panel lost-password">
					<form noValidate onSubmit={formik.handleSubmit}>
						{formError ? <div className="panel-addon error">{formError}</div> : errorMessage ? <div className="panel-addon error">{errorMessage}</div> : null}
						<div className="panel">
							<h1 className="panel-title">{ dictionary.forms.phrases.passwordForgottenQ }</h1>
							<p className="panel-text">
								{ dictionary.forms.phrases.passwordForgottenInstructions }
							</p>
							<div className="form-item">
								<label className="form-label" htmlFor="new_password_first">{ dictionary.forms.fields.newPassword }</label>
								<input
									type="password"
									id="new_password_first"
									disabled={loading}
									className={classNames('form-input', {
										'is-invalid': formik.touched.new_password_first && formik.errors.new_password_first
									})}
									{...formik.getFieldProps('new_password_first')}
								/>
								{formik.touched.new_password_first && formik.errors.new_password_first ? (
									<span className="form-error" aria-live="polite">
										{formik.errors.new_password_first}
									</span>
								) : null}
								{formik.touched.new_password_first ? (
									!passwordValid ?
										<span className="form-help">{ dictionary.forms.phrases.passwordHelp }</span> : <span className="form-success">{ dictionary.forms.phrases.passwordSecure }</span>
									) : null
								}
							</div>
							<div className="form-item">
								<label className="form-label" htmlFor="new_password_second">{ dictionary.forms.fields.repeatPassword }</label>
								<input
									type="password"
									id="new_password_second"
									disabled={loading}
									className={classNames('form-input', {
										'is-invalid': formik.touched.new_password_second && formik.errors.new_password_second
									})}
									{...formik.getFieldProps('new_password_second')}
								/>
								{formik.touched.new_password_second && formik.errors.new_password_second ? (
									<span className="form-error" aria-live="polite">
										{formik.errors.new_password_second}
									</span>
								) : null}
							</div>
							<div className="form-buttons">
								<button type="submit" className="btn primary" disabled={loading}>{ dictionary.forms.actions.send }</button>
							</div>
						</div>
					</form>
				</div>
			</div>
			{
        showEditSuccess ? (
          <Modal onClose={() => setShowEditSuccess(false)}>
            <div className="modal-icon modal-success">
              <div className="icon" aria-label="✓"></div>
            </div>
            <div className="modal-text text-center">
              { dictionary.profile.editSuccess.ok }
            </div>
						<div className="modal-text text-center">
							<Link to="/login" className="btn primary">{ dictionary.forms.actions.logIn }</Link>
						</div>
          </Modal>
        ) : null
      }
		</>
	)
}

export default ChangePassword
